.blog1 li{
    list-style: none;
    border-bottom:  1px solid #dfe6e9;
    width: 230px;
    padding: 18px;
    background-color: #ecf0f1;
}.blog1 li a{
    font-size: 20px;
    text-decoration: none;
    color: #6c5ce7

} 
.blog1 li h3{
    color: white;
}
.blog1 li a:hover{
    color: yellow;
    text-decoration: underline;
}
.blog1{
    float: right;
    position: sticky;
    top:200px !important;
    right: 50px;
    margin-top:50px;
}
