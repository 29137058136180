* {
  padding: 0px;
  margin: 0px;
}
.homeform {
  width: 364px;
  height: 554px;
}

/* .d-block {
  position: relative;
} */

/* .para {
  position: absolute;
  top: 100px;
  left: 26px;
  color: whitesmoke;
  font-size: 60px;
  font-weight: bold;
  right: 150px;
} */

/* .para1 {
  position: absolute;
  top: 70px;
  left: 700px;
  color: rgba(0, 0, 0, 0.836);
  font-size: 65px;
  font-weight: bold;
  right: 170px;
} */

/* input {
  margin-left: 25px;
  padding: 10px;
  border: none;
  margin-top: 10px;
} */

/* select{
  margin-left: 80px;
  padding: 16px;
  margin-left: 40px;
} */

.button {
  margin-left: 116px;
  margin-top: -8px;
  background-color: yellow;
  color: black;
  border: 1px solid yellow;
  padding: 10px;
}
.enquiry {
  border: 1px solid rgba(0, 0, 0, 0.13);
  margin-top: -595px;
  margin-left: 856px;
  margin-right: 25px;
  padding: 19px;
  background-color: #00162b;
  margin-bottom: 20px;
  color: white;
  position: absolute;
  height: 595px;
  width: 384px;
}
* {
}

.main_description {
  display: flex;
  justify-content: space-between;
  margin-left: 100px;
}

.description {
  margin-left: 100px;
  font-family: "Times New Roman";
  font-size: 20px;
  text-align: justify;
  margin-right: 100px;
}

.description h1 {
  color: coral;
  font-weight: bolder;
}

#btn {
  margin-left: 700px;
}

.division {
  text-align: center;
  margin-top: 20px;
  border: 1px solid black;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px;
  color: whitesmoke;
  background-color: black;
  padding: 20px;
  background: rgb(0, 14, 36);
  background: linear-gradient(
    90deg,
    rgba(0, 14, 36, 1) 0%,
    rgba(19, 176, 148, 1) 35%,
    rgba(45, 170, 195, 1) 100%
  );
}

.division h3 {
  font-family: "Gill Sans";
}

.division h4 {
  font-family: "Gill Sans";
}

.division span {
  font-size: 30px;
}

SupportAgentIcon {
  width: 20px;
}
.carousel {
  position: relative !important;
}

#card1 {
  background-color: bisque;
  margin-left: 30px;
}

video {
  margin-left: 175px;
  /* margin-right: 50px; */
  margin-top: 50px;
  margin-bottom: 50px;
  width: 1000px;
  border-radius: 20px;
}

#card2 {
  background-color: white;
  margin-left: 50px;
}

.choose {
  display: flex;
  justify-content: space-evenly;
  margin-left: 20px;
}

.us {
  margin-left: 100px;
  margin-right: 100px;
  text-align: center;
}

.tagline img {
  margin-left: 50px;
  text-align: center;
  padding: 20px;
  position: relative;
}

.tag h3 {
  position: absolute;
  top: 10px;
  left: 40px;
  right: 40px;
  color: black;
}
.bg_imgblue img {
  position: relative;
}
.bg_imgblue1 {
  position: absolute;
  top: 48rem;
  left: 150px;
}
.bg_imgblue1 p {
  font-size: 17px;
  color: #fff;
}
.bg_imgblue1 h2 {
  color: yellow;
  margin-left: -100px;
  margin-top: -74px;
}
/* .labels{
  height:405px !important;
  border: none;
} */
.labels input[type="text"] {
  width: 300px;
}
.labels input[type="email"] {
  width: 300px;
  margin-left: 13px;
}
.labels input[type="number"] {
  width: 300px;
  margin-left: 13px;
}
.labels p {
  color: black;
  font-size: 18px;
}
#se_in {
  margin-left: 13px;
  width: 140px;
}
#se_in1 {
  margin-left: 30px;
  width: 130px;
}
/* .labels form {
  position: absolute;
  top: 149px;
  right: 50px;
  height: 402px ;
} */

.proverb {
  position: absolute;
  color: #fff;
  top: 330px;
  left: 100px;
  font-weight: bold;
  font-size: 50px;
}

.inputs2 {
  display: flex;
  /* width: 100px; */
}
.inputs2 input[type="date"] {
  width: 138px;
  margin-left: 13px;
}
.inputs2 select {
  margin-left: 33px;
  width: 130px;
  padding: 10px;
}

.main_description {
  position: relative;
}

.main_description h1 {
  position: absolute;
  top: 42px;
  display: flex;
  left: -29px;
  color: yellow;
  font-size: 29px;
}

.main_description h2 {
  position: absolute;
  top: 42px;
  display: flex;
  left: 108px;
  color: white;
  font-style: inherit;
  font-weight: 20;
  font-size: 29px;
}

.about img {
  position: relative;
}
.best {
  border: 1px solid rgb(176, 170, 170);
  background-color: white;
  padding: 20px;
  height: 100%;
  width: 100%;
}
.best p {
  text-align: justify;
  color: #617e9a;
  font-size: 15px;
}
.cent {
  background-color: #9d041ba1;
  width: 300px;
  padding: 5px;
}
.offer {
  background-color: #9d041ba1;
  color: white;
  padding: 10px;
  /* display: flex; */
  font-size: small;
  width: 400px;
  height: 153px;
}
.offer p {
  border: 1px solid #ffff00;
  padding-top: 4px;
  border-radius: 50%;
  height: 50px;
  width: 55px;
  text-align: center;
  font-size: 25px;
}
.asdfgh_23 span {
  margin-left: 8px;
}

.features {
  display: flex;

  float: none;
  border-left: 1px solid #e6ebf1;
}

.background {
  position: relative;
}

.content {
  position: absolute;
  top: 189rem;
  left: 10px;
}

.content h6 {
  color: yellow;
}

.display {
  position: absolute;
  top: 140rem;
  left: -10px;
  width: 100%;
  /* margin-right: -16px; */
}

.bg_blue {
  position: relative;
}

.display h1 {
  /* margin-left: 70px; */
  color: #9d041b;
  /* /* margin-right: 150px; */
  text-align: center;
}
.display h6 {
  /* margin-left: 43px; */
  /* color: yellow; */
  /* margin-right: 37px; */
  text-align: center;
}
.display img {
  margin-left: 98px;
  /* color: yellow; */
  /* margin-right: 37px;   */
  /* margin:  auto; */
}
.display .col-2 {
  width: 18.66px !important;
}
.display .col-4 {
  width: 352.33px !important;
}

.gCDpsR,
.cjzNGz {
  color: blue !important;
  background-color: white !important;
  border: 1px solid white !important;
  display: none;
}

.eVXyLj,
.jrqawY {
  background-color: yellow !important;
  box-shadow: none !important;
}

.bg_color {
  background-color: #00162b9b;
}

/* textarea {
  padding: 10px;
  margin-top: 16px;
  border-radius: 0px !important;
  border: none;
  background-color: white !important;
  margin-left: 0px !important;
} */

.loc {
  position: absolute;
  top: 221rem;
  left: 440px;
}

.map img {
  position: relative;
  /* background-color: #00162ba3; */
}

/* .ancor1 {
  text-transform: uppercase;
  text-decoration: none;
  font-size: 20px;
  padding: 16px 32px;
  border: 1px solid #1e272e;
  font-family: "Dosis", sans-serif;
  color: #070708;
  position: relative;
  z-index: 1;
  transition: var(--trans);
  overflow: hidden;
  cursor: pointer;
}

.ancor1::before {
  content: "";
  position: absolute;
  top: 624;
  left: 0;
  background-color: black;
  width: 100px;
  height: 100%;
  transform: translateX(-100%);
  transition: var(--trans);
}

.ancor1:hover:before {
  transform: translateX(0);
  z-index: -1;
}

.ancor1:hover {
  color: #ffff;
} */

/* .dvkxnn:before {
  background-color: #000000ab;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 110;
width: 100%;
z-index: 999;
}  */
