.header .navbar-brand {
  display: flex;
  align-items: center;
  gap: 17px;
  line-height: 20px;
}

.add_0 {
  padding: 20px 40px;
  display: flex;
  align-items: center;
  background-color: aliceblue;
  border-radius: 10px;
  gap: 35px;
  margin-top: 135px;
}
.di_90 {
  height: 300px;
  width: 300px;
}
.fw_90 {
  display: flex;
  justify-content: center;
}
input.name_0 {
  padding: 7px;
  border: 1px solid #141619;
  border-radius: 5px;
  outline-width: 0;
  font-size: 16px;
}
label {
  font-size: 18px;
  font-weight: 500;
}
.paginationBttns {
  width: 80%;
  height: 40px;
  list-style: none;
  color: #89131a;
  display: flex;
  justify-content: center;
}

.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #fdd12d;
  color: #fdd12d;
  cursor: pointer;
  text-decoration: none;
}

.paginationBttns a:hover {
  color: white;
  background-color: #fdd12d;
}

.paginationActive a {
  color: white;
  background-color: #fdd12d;
}
.add-90 button {
  width: 100%;
  border: 1px solid #0d6efd;
  background: #0d6efd;
  padding: 4px;
  border-radius: 5px;
  color: white;
  font-size: 19px;
}
.left-side {
  background-color: #fcfcfc;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 900px;
  top: 0px;
  position: sticky;
  overflow: hidden;
  overflow-y: scroll;
  width: 268px;
}
.webmanagement {
  background-color: #8d2d1a;
}
.webmanagement li {
  color: #fff;
}
.webmanagement li:hover {
  background-color: #935049;
}
.webmanagement1 {
  background-color: #5e4340;
}

.scrolll_bar {
  overflow: hidden;
  overflow-x: scroll;
}
.si09 a {
  color: #89131a;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
}
/* .si09 a:hover {
  color: #ffb804;
} */
.si09 ul {
  list-style: none;
  padding-top: 6px;
  background-color: rgb(252 202 11) !important;
  padding-left: unset;
}
.add-gr {
  margin-left: 3rem !important;
}
.a-ele {
  margin: 10px 0px;
  /* border-radius: 40px; */
  padding: 6px;
  font-size: 18px;
}
.ad-b button {
  border-radius: 3px;
  color: white;
  font-size: 18px;
  border: 1px solid;
  background-color: #9d041b;
  padding: 5px;
  font-weight: 500;
}
.table > thead {
  /* background-color: #8cb603 !important; */
  color: #000 !important;
}
.btn-primary {
  background: #ffb902 !important;
  border-color: #ffb902 !important;
}
.vi_0 {
  border: 2px solid #dee2e6;
  outline-width: 0px;
  padding: 7px;
  border-radius: 5px;
  width: 100%;
}
.vi_1 {
  border: 2px solid #dee2e6;
  outline-width: 0px;
  padding: 7px;
  border-radius: 5px;
  width: 96%;
}
.dask {
  border: 2px solid #dee2e6;
  padding: 21px;
  border-radius: 5px;
  background-color: #dee2e6;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.do-p {
  font-size: 25px;
  color: #141619;
  font-weight: 600;
  padding-bottom: 11px;
  text-align: center;
}
.no-p {
  border: 1px solid black;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
}
.no-p p {
  margin-top: 16px;
}

.do-n {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}
.active-0 {
  /* background: #f08d15; */
}
.a-ele:hover {
  /* background: #8f1612; */
}
label {
  color: #842029;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%),
    0 2px 6px 0 rgb(206 206 238 / 54%);
}
.me-2 {
  margin-right: 0.5rem !important;
}
.rounded-circle {
  border: 2px solid black;
  border-radius: 50%;
}
.ad_exp {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.modal-content {
  width: 100% !important;
}
.lab button {
  margin-top: 38px;
}
.form-5 {
  margin: auto;
  margin-top: 20px;
  padding: 20px;
  width: 900px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 20px;
  border: 1px solid black;
}
.form-14 {
  margin: auto;
  margin-top: 20px;
  padding: 20px;
  width: 900px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 20px;
  border: 1px solid black;
}
.text-underline {
  border-bottom: 1px dotted;
  width: 500px;
}
/* dashboard start here */
.wraper-0 {
  background: white;
  padding: 7px 7px 15px 7px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: rgba(65, 14, 14, 0.35) 0px 5px 15px;
}
.wrapper-ad p {
  color: #9d041b;
  font-size: 18px;
  font-weight: 500;
}
.wrapper-ad {
  /* display: flex;
  justify-content: space-between;
  align-items: baseline; */
  border-bottom: 1px solid darkgray;
  padding-bottom: 10px;
}
.admin-list.res-0 {
  min-height: 370px;
  max-height: 370px;
}
.admin-list {
  margin-top: 6px;
  max-height: 192px;
  min-height: 192px;
}
.admin-text {
  font-size: 14px;
}
.label-success {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  line-height: 1;
  background-color: green;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
.ad-pa {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.view-wrap {
  float: right;
}
.view-wrap a {
  text-decoration: none;
  color: black;
}
.active0 {
  background-color: #8d2d1a;
  color: white;
  padding: 10px;
}
.active1 {
  background-color: #8d2d1a;
  color: white;
  padding: 10px;
  margin: 10px 0px;
}
  .active2 {
    background-color: #935049;
  }
