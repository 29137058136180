.footer {
  display: flex;
  justify-content: space-evenly;
  background-color: rgb(2, 2, 30);
  color: beige;
  padding: 50px;
}
.ultra_main {
  margin-top: 40px;
}
/* a {
  font-size: 15px;
  text-decoration: none;
}
a:hover {
  color: burlywood;
} */
.Account,
.links,
.categories,
.contact {
  margin-left: 40px;
}
.main-footer {
  display: flex;
  justify-content: space-evenly;
  /* border: 1px solid black; */
  padding: 10px;
  align-items: center;
  background-color: rgba(255, 255, 0, 0.373);
}
/* .follow {
  margin-top: 20px;
  margin-left: 200px;
}
.follow img {
  margin-left: 10px;
} */
.icons {
  display: flex;
  margin-right: 400px;
  width: 400px;
}
.phoneicon {
  margin-top: 20px;
  height: 50px;
}
.copyright{
    background-color: black;
    color: beige;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.copyright img{
   margin-right: 20px;
}