.main_traffic img{
    position: relative;
}
.main_traffic h1 {
    position: absolute;
    top: 268px;
    left: 382px;
    font-size: 53px;
    color: black;
    font-style: italic;
}
.traffic p{
        text-align: center;
        width: 1000px;
        margin: auto;
        font-size: 20px;
        font-family: 'Times New Roman';
        font-weight: 200;
}
.traffic h1{
    text-align: center;
    color: #9d041b;
    margin: 20px;
}
.traffic img{
    margin-left: 400px;
    margin-right: 500px;
    margin-bottom: 50px;
    margin-top: 50px;
}
.mandatory h2{
    text-align:start;
    color: rgb(190, 184, 182);
    margin: 20px;
}
.mandatory img{
    margin-left: 400px;
    margin-right: 500px;
    margin-bottom: 50px;
    margin-top: 50px;

}.mandatory p{
    width: 1200px;
    text-align: justify;
    margin: 20px;
    font-size: 18px;
}