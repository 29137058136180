.navbar {
  /* background-color: #fff !important; */
  /* border: 1px solid; */
  background-color: rgb(252 202 11) !important;
  height: 85px;
}
.ms-5 {
  margin-left: 9rem !important;
}
.card a {
  text-decoration: none;
  color: wheat;
}
.carddata {
  background-color: white;
  padding: -5px;
  display: flex;
}
.card a:hover {
  color: aquamarine;
}
.nav-item :hover {
  color: #9d041b !important;
  text-decoration: none !important;
}
.nav-item {
  font-size: 20px !important;
  /* font-weight: bold !important; */
  font-family: "Times New Roman";
  color: whitesmoke !important;
}
.dropdown-menu {
  text-decoration: none !important;
}
.title {
  color: #00162b;
  font-size: x-large;
  padding: 20px;
  display: flex;
  font-weight: bold;
  font-style: inherit;
}
.callmebtn {
  width: 100px;
  padding: 10px;
  color: black;
  background-color: yellow;
}
.navvv {
  /* background-color: #00162b; */

  /* color: whitesmoke; */
  /* width: 1500; */
  font-style: inherit;
  font-weight: none;
}
.navbar-nav li {
  font-size: 20px;
  font-weight: 600;
}
.navbar-nav li:hover {
  color: #9d041b;
}
/* .dropdown-menu[data-bs-popper] {
  top: 100%;
  left: unset !important;
  margin-top: var(--bs-dropdown-spacer);
} */
