/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.bg_blue1 {
  /* position: relative; */
}

.content {
  /* position: absolute; */
  top: 183rem;
  color: aliceblue;
}
.box .box-card {
  transform: rotateY(0);
  transition: all 0.5s ease-in-out 0s;
}
.box:hover .box-card {
  transform: rotateY(-90deg);
}
.box-content {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
}
.box {
  position: relative;
  perspective: 1000px;
  margin-left: 50px;
}
.box .box-content .card {
  background-color: rgba(180, 44, 55, 0.597) !important;
  border: 5px solid yellow;
  color: white;
  transform: rotateY(90deg);
  transition: all 0.5s ease-in-out 0s;
}
.box:hover .box-content .card {
  transform: rotateY(0);
}
.box .box-card .card .card-header {
  display: flex;
}
.box .box-card .card li {
  color: black !important;
  margin-top: 20px;
}
@media only screen and (max-width: 768px) {
  /* .d-flex{
    display: block !important;
  } */
  .bg_blue img {
    height: 100%;
  }
  .row {
    /* display: block; */
    /* margin-top: 20px ; */
  }
  .row .col-4 {
    margin-top: 20px !important;
  }
  /* .bg_blue1{
    height: 1000px !important;
    position: relative;
    width: 100%;
  } */
  .content {
    position: absolute;
    top: 650px;
    width: 10% !important;
    margin-right: 20px;
  }
}
