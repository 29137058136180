/* .footer-mid-wrap {
    padding: 500px 0px 160px !important;
  } */

.based .-footer {
  padding: 51px 20px;
}

.icons-footer {
  padding-right: 10px;
  font-size: 18px;
  font-weight: 600;
}

.running-taxi {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100px;
  left: 0;
  bottom: 0;
  z-index: 2;
}
.running-taxi .taxi {
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
  position: absolute;
  width: 210px;
  height: 100px;
  right: 0;
  bottom: -15px;
  animation: running-anim 20s linear infinite;
}
.running-taxi .taxi-2 {
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
  position: absolute;
  width: 210px;
  height: 100px;
  right: 30%;
  bottom: -15px;
  animation: running-anim 30s linear infinite;
}
@keyframes running-anim {
  0% {
    right: -30%;
  }
  100% {
    right: 100%;
  }
}
