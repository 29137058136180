.continue{
    height:200px;
}
.timing{
    height: 56px;
    padding: 15px;
    margin-left: 26px;
    width: 277px;
    margin-top: 30px;
}
