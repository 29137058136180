:root {
  --trans: all 0.3s linear;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.col-6 h3 {
  margin-top: 15px;
}

.con_tact .col-4 a {
  margin-top: -15px;
  margin-left: 64px;
  width: 8rem;
}

.rw2 .col-lg-10 .row .col-6 .col-4 .btn1 {
  margin-top: -37px;
  margin-left: 457px;
  width: 8rem;
}

.upload input[type="file"] {
  width: 303px;
  padding: 10px;
}

#username {
  margin-left: 61px;
}

#rno {
  margin-left: 15px;
}

#sno {
  margin-left: 28px;
}

#email {
  margin-left: 64px;
  border: 1px solid black;
}

#mobile {
  margin-left: 53px;
  border: 1px solid black;
}

.rw2 .col-lg-10 .row .col-6 .btn1 {
  margin-top: -37px;
  margin-left: 457px;
  width: 8rem;
}

.form1 a {
  margin-left: 130px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.module {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/skyscrapers.jpg);
  background-size: cover;
  height: 130px;
  position: relative;
}

.mid h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  color: white;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 2rem;
  transform: translate(-50%, -50%);
}

.breadcrumbs {
  display: flex;
  list-style: none;
}

.breadcrumbs li {
  font-size: 18px;
  border: none;
}

.breadcrumbs a {
  text-decoration: none;
  color: white;
}

.gCDpsR,
.cjzNGz {
  color: blue !important;
  background-color: white !important;
  border: 1px solid white !important;
  display: none;
}

.eVXyLj,
.jrqawY {
  background-color: yellow !important;
  box-shadow: none !important;
}

.ancor1 {
  text-transform: uppercase;
  text-decoration: none;
  font-size: 15px;
  padding: 10px 20px;
  font-family: "Dosis", san-serif;
  color: black;
  position: relative;
  z-index: 1;

  overflow: hidden;
  cursor: pointer;
  border-radius: 10px;

  background-color: yellow;
}

.ancor1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: yellow;
  width: 100%;
  height: 100%;

  opacity: 0;
  border-radius: 10px;
}

.ancor1:hover:before {
  z-index: -1;
  opacity: 1;
}

.ancor1:hover {
  color: Black !important;
  border: 1px solid Yellow !important;
}

.card a {
  color: black;
}

.loc .card {
  color: white;
}

.form2 {
  margin-left: 300px !important;
  margin-top: 20px;
}

.form3 {
  margin-left: 36px !important;
  margin-top: 20px;
}

.form2 label {
  color: Black;
  font-weight: bold;
}

.form2 input[type="text"] {
  margin-left: 82px !important;
  border: 1px solid rgb(0, 26, 51) !important;
}

.form2 input[type="number"] {
  margin-left: 47px !important;
  border: 1px solid rgb(0, 26, 51) !important;
}

.form2 input[type="email"] {
  margin-left: 54px !important;
  border: 1px solid rgb(0, 26, 51) !important;
}

.form2 input[type="date"] {
  margin-left: 18px !important;
  border: 1px solid rgb(0, 26, 51) !important;
}

#bg-34 {
  margin-left: 22px;
  margin-bottom: 18px;
  border: 1px solid rgb(0, 26, 51) !important;
}

.button-group {
  display: flex;
}

.button-group button {
  padding: 8px 16px;
  background-color: #eee;
  border: none;
  cursor: pointer;
}

.button-group button.active {
  background-color: yellow;
}

.row .col-6 p {
  margin-top: 13px;
}

.bg-39 label {
  color: black;
}

.lname label {
  color: black;
}

.col label {
  color: black;
}

#email1 {
  margin-left: 51px !important;
}

#mobile1 {
  margin-left: 38px !important;
}

.col-4 p {
  margin-bottom: 16px;
  margin-top: 5px;
}

select {
  margin-top: 10px;
  width: 200px;
  margin-left: 108px;
}

textarea {
  margin-left: 0px !important;
  margin-bottom: 10px;
}

.tool-bar {
  background-color: #9d041b;
  color: white;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
}

.sidebar {
  position: absolute;
  background-color: #9d041b;
  color: white;
  top: 12.8rem;
  left: 0rem;
  height: 452px;
  width: 280px;
  transform: translateX(-100%);
  transition: all 0.4s;
  opacity: 0;
  z-index: 1;
}

.sidebar a {
  color: white;
}

.sidebar--open {
  transform: translateX(0%);
  opacity: 1;
}

.sidebar li {
  list-style: none;
  padding: 1rem 2rem;
  border-bottom: solid white 1px;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: 300;
}

.sidebar li:hover {
  background-color: #9d041b;
  color: black !important;
}

.sidebar a {
  text-decoration: none;
}

.tool-bar {
  background-color: #9d041b;
  color: white;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
}

.backdrop {
  position: fixed;
  top: 17rem;
  left: 2.1rem;
  width: 100%;
  height: 100vh;

  display: none;
}

.backdrop--open {
  display: block;
}

.sidebar1 {
  position: absolute;
  background-color: #9d041b;
  color: white;
  top: 12.8rem;
  left: 0rem;
  height: 452px;
  width: 280px;
  transform: translateX(-100%);
  transition: all 0.4s;
  opacity: 0;
  z-index: 1;
}

.sidebar1 a {
  color: white;
}

.sidebar1--open {
  transform: translateX(0%);
  opacity: 1;
}

.sidebar1 li {
  list-style: none;
  padding: 1rem 2rem;
  border-bottom: solid white 1px;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: 300;
}

.sidebar1 li:hover {
  background-color: #9d041b;
  color: black !important;
}

.sidebar1 a {
  text-decoration: none;
}

.backdrop {
  position: fixed;
  top: 17rem;
  left: 2.1rem;
  width: 100%;
  height: 100vh;

  display: none;
}

.backdrop--open {
  display: block;
}

.table {
  width: 100% !important;
}

.cs .table > thead {
  background-color: rgb(157, 4, 27) !important;
}

.bg-35 {
  margin-top: 20px !important;
}

.main_employee .col-5 input[type="text"] {
  border: 1px solid black !important;
  margin-left: 0px !important;
}

.main_employee .col-5 input[type="date"] {
  border: 1px solid black !important;
  margin-left: 0px !important;
}

.main_employee .col-5 label {
  color: black !important;
}

.main_employee form {
  box-shadow: none !important;
  border: none !important;
  width: 24rem;
  background-color: white !important;
}

.bg-39 {
  margin-left: 21px !important;
}

.main_employee h2 {
  margin-left: 20rem;
}

#name {
  width: 300px;
  padding: 10px;
}

#select {
  width: 145px;
  padding: 13px;
}

.main_employeee form {
  box-shadow: none !important;
  border: none !important;
  width: 52rem;
  background-color: white !important;
  margin: auto;
}

.main_employeee .col input[type="text"] {
  border: 1px solid black !important;
  margin-left: 0px;
  width: 22rem;
}

.main_employeeee textarea {
  border-radius: 0px !important;
  border: none;
  background-color: white !important;
}

#finame {
  width: 300px;
  padding: 10px;
}

.main_employeeee select {
  width: 300px;
  padding: 10px;
}

.main_employeeee form {
  box-shadow: none !important;
  border: none !important;
  width: 52rem;
  background-color: white !important;
}

.main_employeeee textarea {
  border: 1px solid black;
  width: 46rem;
}

/* .main_employeee input[type="text"] {
  padding: 10px !important;
  width: 300px;
  border: 1px solid black !important;
} */
.main_employeee input[type="text"],
.main_employeee input[type="email"],
.main_employeee input[type="number"],
.main_employeee input[type="file"],
.main_employeee textarea,
.main_employeee input[type="date"] {
  border: 2px solid #dee2e6;
  outline-width: 0px;
  padding: 7px;
  border-radius: 5px;
  width: 100%;
}
/* .main_employeee input[type="email"] {
  margin-left: 0px;
  border: 1px solid black;
  padding: 10px !important;
  width: 300px;
} */

/* .main_employeee input[type="number"] {
  margin-left: 1px;
  border: 1px solid black;
  padding: 10px !important;
  width: 300px;
} */

.main_employeee .col-10 label {
  margin-top: 20px !important;
}

.timeinput {
  width: 300px;
  padding: 10px;
}

.bg-50 {
  margin-top: -28px !important;
  margin-bottom: 0px !important;
}

.enqlabel label {
  color: white;
}

.enqlabel input[type="text"] {
  border: 1px solid black;
  margin-left: 47px;
  padding: 10px;
}

.enqlabel input[type="email"] {
  border: 1px solid black;
  margin-left: 23px;
  padding: 10px;
}

.enqlabel input[type="number"] {
  border: 1px solid black;
  margin-left: 13px;
  padding: 10px;
}

.enqform {
  height: 255px !important;
  background-color: white;
  box-shadow: none;
  border: none;
  width: 400px;
  margin-top: 20px;
}

.enqform label {
  color: black !important;
}

.loc textarea {
  width: 605px;
}

.enqform .enqbtn {
  float: right !important;
  margin-right: 10px;
}

.enqform .enqbtn1 {
  float: left !important;
}

.comment {
  background-color: white;
  height: 457px;
  margin-left: 5px;
}

.comment input {
  padding: 10px;
}

.rww .A1 p {
  margin-right: 30px;
}

.container .rww p {
  margin-right: 30px;
}

.container .h-001 h3 {
  color: #00162b;
}

.container .h-001 h6 {
  color: rgb(88, 129, 169);
}

#cbtn2 {
  margin-left: 30px;
}

.h-02 .h-05 {
  display: flex;
}

.h-02 .h-05 .h-06 h6 {
  font-size: 13px;
  padding: 10px;
  margin-top: -20px;
}

.h-02 .h-05 .h-06 {
  border: 1px solid black;
  border-radius: 50%;
  text-align: center;
  padding: 10px;
  margin-top: -180px;
  width: 100px;
  height: 100px;
}

.h-02 .h-03 {
  display: flex;
}

.h-02 .h-03 h6 {
  color: rgb(88, 129, 169);
}

.h-02 h3 {
  color: #00162b;
}

.container .h-07 {
  margin-top: -40px;
}

.h-001 .h-002 {
  display: flex;
}

.h-001 .h-002 .h-004 .h-005 {
  display: flex;
  margin-left: 20px;
}

.h-001 .h-002 .h-004 h6 {
  text-align: justify;
  padding: 20px;
  margin-top: -19px;
}

.h-001 .h-002 .h-004 h4 {
  margin-left: 20px;
}

.h-001 .h-002 .h-004 .h-005 .h-006 {
  border: 1px solid black;
  border-radius: 50%;
  text-align: center;
  padding: 10px;
  margin-top: -20px;
  margin-left: 10px;
  width: 100px;
  height: 100px;
}

.h-001 .h-002 .h-004 .h-007 {
  margin-top: 8px;
  margin-left: 20px;
}

.h-001 .h-002 .h-004 .h-005 .h-006 h6 {
  margin-top: -20px;
  padding: 10px;
  color: black;
  font-size: 13px;
}

.poped1 {
  background-color: white;
  height: 470px;
  color: #00162b;
}

.cross1 {
  margin-left: 358px;
  margin-top: -75px;
}

.poped input {
  margin-left: -137px;
}

.poped .poped1 .radio h6 {
  margin-left: -120px;
}

.labels input[type="text"],
.labels input[type="email"] {
  margin-left: 13px;
}

.wertyuio_23 p {
  text-align: justify;
  font-size: 1.2rem;
  color: #1b1464;
  width: 450px;
}

.wertyuio_23 {
  background-color: white;
  width: 550px;
  margin: 20px;
  padding: 40px;
  height: 700px;
  margin-left: -134px;
}

.labels form {
  color: white;
  padding: 10px;
  border: 1px solid whitesmoke;
  width: 362px;

  box-shadow: 0 8px 32px 0 rgba(19, 19, 20, 0.37);
  background-color: #fcca0b;
  position: absolute;
  top: 17px;
  right: 50px;
  height: 415px;
  z-index: 1;
}

.carousel-indicators {
  display: none !important;
}

.carousel-caption {
  top: 7rem;
  right: 62% !important;
  left: unset !important;
  font-size: 20px;
}

.labels input {
  width: 300px;
  padding: 10px;
  margin-top: 10px;
  background-color: whitesmoke;
}

.labels select {
  width: 300px;
  padding: 10px;
  margin-top: 10px;
  background-color: whitesmoke;
}

.form2 label,
.form2 h4 {
  color: white;
}

.form2 {
  color: white;
  padding: 10px;
  border: 1px solid whitesmoke;
  width: 464px;
  margin: auto;

  margin-top: 20px;
  box-shadow: 0 8px 32px 0 rgba(19, 19, 20, 0.37);
  background-color: #9d041b;
}

#bg-34 {
  margin-left: 25px !important;
}

.form2 input {
  width: 300px;
  padding: 10px;
  margin-top: 10px;
  background-color: whitesmoke;
}

.form2 input[type="date"] {
  width: 300px;
  padding: 10px;
  margin-top: 10px;
  background-color: whitesmoke;
}

#Rmobile {
  margin-left: 113px;
}

#Remail {
  margin-left: 124px;
}

#Rname {
  margin-left: 122px;
}

.formselect,
.formselect1 {
  width: 93% !important;
}

#Rpassword1 {
  margin-left: 61px;
}

#Rpassword {
  margin-left: 90px;
}

#Rusername {
  margin-left: 87px;
}

#Rdriving {
  margin-left: 50px;
}

#Rcity {
  margin-left: 138px;
}

#Rutype {
  margin-left: 108px;
  width: 300px;
}

#Rcity {
  margin-left: 137px;
  width: 300px;
}

#Rdriving {
  margin-left: 50px;
  width: 300px;
}

#Rrno {
  margin-left: 74px;
}

#Rmobile {
  margin-left: 113px;
}

#Remail {
  margin-left: 124px;
}

.registration-container form {
  color: #000;
  margin-left: auto;
  margin-top: 20px;
  padding: 10px;
  width: 570px;
}

.registration-container input,
.registration-container select {
  background-color: #f5f5f5;
  border-radius: 10px;
  margin-top: 10px;
  padding: 10px;
  width: 300px;
}

.registration-container select {
  width: 300px;
  padding: 10px;
  margin-top: 10px;
  background-color: whitesmoke;
}
#name1 {
  width: 300px;
  padding: 10px;
  margin-left: 53px;
  margin-top: 10px;
}

#name2 {
  width: 300px;
  padding: 10px;
  margin-left: 90px;
  margin-top: 10px;
}

#name3 {
  width: 300px;
  padding: 10px;
  margin-left: 78px;
  margin-top: 10px;
}

.login-container form {
  color: white;
  padding: 10px;
  border: 1px solid whitesmoke;
  width: 455px;
  margin: auto;

  box-shadow: 0 8px 32px 0 rgba(19, 19, 20, 0.37);
  background-color: #9d041b;
}

#username1 {
  margin-left: 32px;
}

#password1 {
  margin-left: 37px;
  margin-bottom: 10px;
}

.login-container input {
  width: 300px;
  padding: 10px;
  margin-top: 10px;
  background-color: whitesmoke;
}

.login-container select {
  width: 300px;
  padding: 10px;
  margin-top: 10px;
  background-color: whitesmoke;
}

.login-container label {
  color: white;
}

.postblog {
  color: white;
  padding: 10px;
  border: 1px solid whitesmoke;
  width: 524px;
  margin: auto;

  margin-top: 20px;
  box-shadow: 0 8px 32px 0 rgba(19, 19, 20, 0.37);
  background-color: #9d041b;
}

.postblog input {
  width: 302px;
  padding: 10px;
  margin-top: 10px;
  background-color: whitesmoke;
  margin-left: 56px;
}

.bg-24 input {
  margin-left: 81px;
}

.bg-22 input[type="file"] {
  width: 300px;
  margin-left: 33px;
}

#cat {
  margin-left: 120px;
}

#pf {
  margin-left: 112px;
}

#cross {
  margin-left: 121px;
}

.postblog select {
  width: 300px;
  padding: 10px;
  margin-top: 10px;
  background-color: whitesmoke;
}

.postblog label {
  color: white;
}

.form1 {
  color: white;
  padding: 10px;
  border: 1px solid whitesmoke;
  width: 416px;
  margin: auto;

  margin-top: 20px;
  box-shadow: 0 8px 32px 0 rgba(19, 19, 20, 0.37);
  background-color: #9d041b;
}

.form1 label {
  color: white;
}

.form1 input[type="text"] {
  margin-left: 44px;
  padding: 10px;
  width: 300px;
}

.form1 input[type="email"] {
  margin-left: 19px;
  padding: 10px;
  margin-top: 10px;
  width: 300px;
}

.form1 input[type="number"] {
  margin-left: 10px;
  padding: 10px;
  margin-top: 10px;
  width: 300px;
}

.form1 textarea {
  margin-top: 10px;
  width: 393px;
}

.col-md-4 {
  flex: 3 0 auto !important;
  width: 33.33333333%;
}

.bg-25 img {
  height: 475px;
}

.wertyuiop_67 {
  border: 1px solid white;
  padding: 15px;
  box-shadow: 0 8px 32px 0 rgba(19, 19, 20, 0.37);
  border-radius: 15px;
  margin-bottom: 10px;
}

.modal-content {
  width: 100% !important;
}

.card:hover {
  box-shadow: 0 8px 32px 0 rgba(19, 19, 20, 0.37);
  border-radius: 15px;
  transition: all 0.5s ease 0.3s;
  transform: scale(1.025);
}

.card {
  transform: scale(1);
}

.qwertyu .card {
  transform: unset;
}

.qwertyu .card:hover {
  transform: unset;
  transition: unset;
  box-shadow: unset;
}

#Stextarea {
  margin-left: 172px !important;
}

.rw p {
  margin-top: 0rem !important;
}

#wallet {
  margin-left: -10px;
}

.slick-prev:before {
  content: "←";
  /* margin-left: 30px !important; */
  font-size: 30px !important;
  color: #000 !important;
}

.slick-next:before {
  content: "→";
  margin-left: -22px !important;
  color: #000 !important;
  font-size: 30px !important;
}
.cbmd_09 {
  float: right;
  /* margin-top: -38px; */
}
.timeslot {
  margin-top: -37px !important;
  margin-left: 251px !important;
}

@media only screen and (min-width: 0px) and (max-width: 320px) {
  .timeslot {
    margin-top: -37px !important;
    margin-left: 251px !important;
  }
  .sidebar {
    top: 8.8rem !important;
  }
  .navbar1 {
    display: none !important;
  }
  .id_card_front {
    margin-left: -42px !important;
    float: unset !important;
    width: unset !important;
  }
  .id_background {
    height: unset !important;
  }
  .totalAmount {
    width: 271px !important;
  }
  .recomnded {
    position: absolute;
    padding: 5px;
    background-color: #ffc107;
    top: 13px;
    color: #fff;
    right: unset !important;
    transform: unset !important;
  }
  .staff_T {
    margin-top: -36px !important;
    margin-left: 112px !important;
  }
  .dfghjkl_0 {
    display: none !important;
  }

  #Rutype {
    margin-left: 0px !important;
    width: 260px !important;
  }

  #Rcity {
    margin-left: 0px !important;
    width: 260px !important;
  }

  #Rdriving {
    margin-left: 0px !important;
    width: 260px !important;
  }

  #Rbutton {
    margin-left: 86px !important;
  }

  .modal-content {
    width: 100% !important;
  }

  .bholp_09 {
    margin-left: 0px !important;
  }

  .wfhid_09 {
    display: none !important;
  }

  .sdfghj_34 {
    height: 51rem;
  }

  .qwerty_0 {
    height: 473px !important;
  }

  .loca {
    display: none;
  }

  .loca1 {
    margin-left: 10rem;

    margin-top: -35px !important;
  }

  .loca1 button {
    width: 6rem !important;
  }

  .navbar-collapse {
    position: relative !important;
    z-index: 999 !important;
    background: white !important;
    top: 5px;

    color: black !important;
    width: 200px;
    left: -11.8px;
  }
  .counts_bg {
    height: 472px;
  }
  .navbar-collapse a {
    padding-left: 10px;
  }
  .login_navbar {
    margin-left: unset !important;
    margin-bottom: 10px !important;
  }
  .button_navbar {
    padding-left: unset !important;
  }
  .dropdown-menu[data-bs-popper] {
    left: unset !important;
  }

  .nav-link {
    color: black !important;
    margin-left: 20px;
  }

  .labels form {
    position: static !important;
    box-shadow: unset !important;
    border: none !important;
    margin-top: -4px;
    margin-left: unset;
  }

  .labels input[type="text"],
  .labels input[type="number"],
  .labels input[type="email"],
  #se_in {
    margin-left: 2px !important;
  }

  .labels input[type="text"],
  .labels input[type="email"] {
    width: 290px !important;
  }

  .labels select {
    margin-left: 0px !important;
    width: 290px !important;
  }

  .zxcvbnm {
    margin-left: -11px;
  }

  .bg-2 {
    width: 100%;
    margin-left: unset;
  }

  .bg-1 {
    margin-left: -112px !important;
    margin-top: -20px;
  }

  .bg_imgblue1 {
    position: absolute;
    top: 108rem !important;
    left: 94px !important;
  }

  .bg_imgblue img {
    position: relative;
    height: 604px;
  }

  .cent {
    position: absolute;
    top: 150rem !important;
    left: 1rem !important;
  }

  .offer {
    position: absolute;
    left: 0 !important;
    top: 159rem !important;
  }

  .best {
    margin-left: -34px;
    width: 115% !important;
    height: 60rem !important;
  }

  .bg_blue img {
    position: relative;
    width: 100%;
    height: 460px !important;
  }

  .display {
    position: absolute;
    top: 349rem !important;
  }

  .display h1 {
    color: yellow;
    text-align: center;
  }

  .content {
    position: absolute;
    top: 409rem !important;
    left: -6rem !important;
  }

  .bg-26,
  .bg-27 {
    width: 17rem !important;
  }

  .bg-30 h2 {
    margin-top: 21px;
    margin-left: 55px;
    margin-bottom: -45px;
  }

  .bg-31,
  .bg-60 {
    margin-left: -94px !important;
  }

  .bg_blue1 {
    position: relative;
  }

  .bg_blue1 img {
    height: 1270px !important;
  }

  .map1 .card {
    width: 19rem !important;
    position: absolute;
    top: 495rem !important;
    left: 19px !important;
  }

  .loc {
    position: absolute;
    top: 506rem !important;
    left: 12px !important;
  }

  .loc .card {
    width: 19rem !important;
  }

  .bg-32 {
    display: flex;

    flex-direction: column;
  }

  .loc .card h6 {
    display: flex;
    flex-direction: column;
  }

  .loc textarea {
    margin-left: 11px !important;
    width: 16.7rem !important;
    height: 4rem;
  }

  .homebooknow {
    margin-left: 40px !important;
  }

  .about img {
    position: relative;
    margin-left: -19px;
    width: 106% !important;
  }

  .homeform {
    width: 100% !important;
    height: 619px !important;
  }

  .best {
    position: static !important;
  }

  .bg-blue1 img {
    height: 79rem !important;
  }

  .bg-12 img {
    width: 94% !important;
    height: 250px !important;
  }

  .col-4 a {
    margin-top: -124px !important;
    margin-left: 73px !important;
    width: 7rem !important;
  }

  .bg-33 span {
    font-size: 20px !important;
  }

  .h-001 .h-002 {
    display: flex;
    flex-direction: column;
  }

  .bg-14 a {
    margin-top: -69px !important;
    margin-left: 187px !important;
  }

  .koCOMj {
    margin-top: -71px;
  }

  .bg-15 {
    width: 60% !important;
  }

  .card {
    width: 192% !important;
    margin-left: -24px;
  }

  .bg-15 .card-body a {
    margin-top: -8px !important;
    margin-left: 73px !important;
    width: 8rem !important;
  }

  .bg-15 .card-body .ms-5 {
    margin-left: 78px !important;
  }

  .enqlabel input[type="text"] {
    width: 180px !important;
    margin-left: 46px !important;
  }

  .enqlabel input[type="email"] {
    width: 180px !important;
  }

  .enqlabel input[type="number"] {
    width: 180px !important;
  }

  .enqbtn {
    margin-right: 25px !important;
  }

  .enqform {
    width: 293px !important;
  }

  .col-lg-2 img {
    margin-left: 3px !important;
  }

  .rw {
    display: flex !important;
    margin-top: 0px !important;
  }

  .rw .col-6 {
    width: 138px !important;
  }

  .row .col-4 .btn1 {
    margin-top: -25px !important;
    margin-left: 116px !important;
  }

  .row .col-lg-10 .col-6 h3 {
    margin-top: 30px !important;
  }

  .row .col-lg-10 .row .col-4 a {
    margin-top: -40px !important;
    margin-left: 102px !important;
    width: 8rem !important;
  }

  .mid h2 {
    top: 60% !important;
  }

  .gallery img {
    margin-left: -22px !important;
  }

  .gallery h4 {
    width: 150px !important;
    margin-left: 90px !important;
  }

  .bg-17 {
    display: none;
  }

  .postblog {
    width: 308px !important;
    height: 52rem;
  }

  .bg img {
    display: none;
  }

  .con {
    position: static !important;
  }

  #pf {
    width: 17rem !important;
    margin-left: 0.5rem !important;
  }

  #cat {
    width: 17rem !important;
    margin-left: 0.5rem !important;
  }

  .bg-21 input[type="text"] {
    margin-left: 0.5rem;
    width: 17rem;
  }

  .bg-22 input[type="file"] {
    margin-left: 0.5rem !important;
    width: 17rem !important;
  }

  #in {
    margin-left: 7px !important;
    width: 17rem !important;
  }

  .bg-23 input[type="number"] {
    margin-left: 7px !important;
    width: 17rem !important;
  }

  .bg-24 input[type="email"] {
    margin-left: 7px !important;
    width: 17.1rem !important;
  }

  .cross {
    margin-left: 254px !important;
  }

  .row .col-4 .card {
    width: 192% !important;
  }

  .col-4 .card-body a {
    margin-top: -7px !important;
    margin-left: 61px !important;
    width: 8rem !important;
  }

  .form1 {
    width: 302px !important;
    height: 405px !important;
    margin-left: 10px !important;
  }

  .bg-11 {
    margin-left: -23px !important;
  }

  .form1 input[type="text"] {
    margin-left: 9px !important;
    width: 265px !important;
  }

  .form1 input[type="email"] {
    margin-left: 8px;
    width: 265px !important;
  }

  .form1 input[type="number"] {
    width: 265px !important;
  }

  .form1 textarea {
    margin-left: 2px !important;
    width: 17.2rem;
  }

  .bg-9 .bg-11 .form1 a {
    width: 139px !important;
    margin-top: -6px !important;
  }

  .bg-11 h4 {
    margin-left: 20px;
    width: 133px;
  }

  .bg-9 {
    margin-left: 0px !important;
    width: 46rem;
  }

  .container .faq p {
    width: 298px !important;
  }

  .faq h3 {
    margin-left: 130px !important;
  }

  .login-container form {
    height: 370px !important;
    width: 320px !important;
  }

  .login-container input[type="text"] {
    margin-left: 2px !important;
  }

  .login-container input[type="password"] {
    margin-left: 1px !important;
  }

  .login-container .btn {
    margin-left: 120px !important;
  }

  .cs {
    overflow: hidden;
    overflow-x: auto;
  }

  input[type="text"] {
    margin-left: unset !important;
    width: 17rem;
  }

  .main_employeeee form {
    width: 300px !important;
  }

  .main_employeeee textarea {
    width: 21rem !important;
  }

  .main_employeeee .add h2 {
    margin-left: 10px !important;
  }

  #stime {
    width: 15rem !important;
    margin-left: 10px !important;
  }

  #etime {
    width: 15rem !important;
    margin-left: 10px !important;
  }

  #student {
    margin-left: 10px !important;
    width: 15rem !important;
  }

  #day {
    margin-left: 10px !important;
    width: 15rem !important;
  }

  #remarks {
    margin-left: 10px !important;
    width: 15rem !important;
  }

  .update h1 {
    margin-top: 20px !important;
  }

  .main_employee form {
    width: 300px !important;
    margin-left: -45px !important;
  }

  .main_employee img {
    margin-left: 3rem !important;
  }

  .main_employee h2 {
    margin-left: 0rem !important;
  }

  #lastname {
    margin-left: 18px !important;
  }

  #from {
    border: 1px solid black;
    margin-left: 1px;
    width: 15rem !important;
  }

  #to {
    border: 1px solid black;
    margin-left: 1px;
    width: 15rem !important;
  }

  #username {
    width: 300px !important;
  }

  input[type="radio"] {
    margin-left: 116px !important;
  }

  .logchoosecar .mt-4 {
    float: right;
    width: 8rem;
    margin-right: 10px !important;
  }

  .logchoosecar .mt-44 {
    width: 7rem;
    float: left;
    margin-bottom: 20px;
    margin-left: 10px !important;
  }

  .instructors h1 {
    margin-left: 100px !important;
  }

  .card {
    width: 107% !important;
    margin-left: -21px;
  }

  .loginstructorsbtn {
    margin-left: 92px !important;
  }

  .loginstructorsbtn1 {
    width: 7rem;
    float: left;
    margin-bottom: 20px;
    margin-left: 10px !important;
  }

  .loginstructorsbtn2 {
    float: right;
    width: 8rem;
    margin-right: 10px !important;
  }

  .labels form {
    top: 483px !important;
  }

  .registration-container form {
    height: 630px !important;
    padding: 10px;
    width: 310px !important;
  }

  .registration-container input[type="text"] {
    margin-left: -5px !important;
  }

  .registration-container input[type="email"] {
    margin-left: -5px !important;
  }

  .registration-container input[type="number"] {
    margin-left: -5px !important;
  }

  #rno {
    margin-left: -5px !important;
  }

  .registration-container select {
    margin-left: -5px !important;
    width: 18.8rem !important;
  }

  .registration-container a {
    margin-left: 102px !important;
  }

  .bg-48 img {
    margin-left: 2px !important;
  }

  .rw1 .col-lg-10 .row .col-md-6 h3 {
    margin-top: 40px !important;
  }

  .btn1 {
    margin-left: 110px !important;
    width: 120px;
    margin-top: -17px !important;
  }

  .timeinput {
    width: 300px;
  }

  .main_employeeee textarea {
    border: 1px solid black;
    width: 18.8rem;
  }
}

@media only screen and (min-width: 321px) and (max-width: 374px) {
  .counts {
    margin-left: unset !important;
  }
  .counts .center {
    margin: auto;
  }
  input[type="text"] {
    margin-left: unset !important;
    width: 17rem;
  }
  .timeslot {
    margin-top: -37px !important;
    margin-left: 251px !important;
  }
  .sidebar {
    top: 8.8rem !important;
  }
  .navbar1 {
    display: none !important;
  }
  .id_card_front {
    margin-left: -42px !important;
    float: unset !important;
  }
  .id_background {
    height: unset !important;
  }
  .totalAmount {
    width: 271px !important;
  }
  .recomnded {
    position: absolute;
    padding: 5px;
    background-color: #ffc107;
    top: 13px;
    color: #fff;
    right: unset !important;
    transform: unset !important;
  }
  .dfghjkl_0 {
    display: none !important;
  }

  .dotted {
    border-right: unset !important;
  }

  #name1 {
    width: 274px !important;
    margin-left: 9px !important;
  }

  #name2 {
    width: 274px !important;
    margin-left: 9px !important;
  }

  #name3 {
    width: 274px !important;
    margin-left: 9px !important;
  }

  #Stextarea {
    margin-left: 23px !important;
    width: 273px !important;
  }

  #floatingTextarea2 {
    width: 300px !important;
    margin-left: -54px !important;
  }

  .main_employeeee form {
    width: 18rem !important;
  }

  #Sbutton {
    margin-left: 7rem !important;
  }

  .sdfjk_25 {
    flex-direction: column !important;
  }

  #Lbutton {
    margin-left: 116px !important;
  }

  #Rutype {
    margin-left: 0px !important;
    width: 260px !important;
  }

  #Rcity {
    margin-left: 0px !important;
    width: 260px !important;
  }

  #Rdriving {
    margin-left: 0px !important;
    width: 260px !important;
  }

  #Rbutton {
    margin-left: 86px !important;
  }

  .dfghio_098 {
    display: flex;
    flex-direction: column;
  }

  .asdfgh {
    margin-left: -10px !important;
  }

  .dhren {
    margin-top: 61px !important;
  }

  .xcvbnm_012 .row {
    margin-left: -10px;
  }

  .instructors_btn {
    margin-top: 62px;
  }

  .wertyuiop_67 {
    box-shadow: unset;
    border: unset;
  }

  .hjerwe {
    margin-left: unset !important;
  }

  .cbmd_09 {
    float: right;
    margin-top: -38px;
  }

  .modal-content {
    width: 100% !important;
  }

  .bholp_09 {
    margin-left: 0px !important;
  }

  .wfhid_09 {
    display: none !important;
  }

  .sdfghj_34 {
    height: 51rem !important;
  }

  .qwerty_0 {
    height: 473px !important;
  }

  .Home {
    height: 606px !important;
  }

  .Home1 {
    margin-left: 91px !important;
  }

  .Home1 h2 {
    margin-left: -53px !important;
  }

  .findcourse {
    margin-left: 42px !important;
  }

  .sendrequest {
    margin-left: 54px !important;
  }

  .display1 {
    height: 615px !important;
  }

  .loca {
    display: none;
  }

  .loca1 {
    margin-left: 10rem;

    margin-top: -35px !important;
  }

  .loca1 button {
    width: 6rem !important;
  }

  .navbar-collapse {
    position: relative !important;
    z-index: 999 !important;
    background: white !important;
    top: 5px;

    color: black !important;
    width: 200px;
    left: -11.8px;
  }
  .counts_bg {
    height: 472px;
  }
  .navbar-collapse a {
    padding-left: 10px;
  }
  .login_navbar {
    margin-left: unset !important;
    margin-bottom: 10px !important;
  }
  .button_navbar {
    padding-left: unset !important;
  }
  .dropdown-menu[data-bs-popper] {
    left: unset !important;
  }

  .nav-link {
    color: black !important;
    margin-left: 20px;
  }

  .logchoosecar .mt-4 {
    float: right;
    width: 8rem;
    margin-right: 10px !important;
  }

  .logchoosecar .mt-44 {
    width: 7rem;
    float: left;
    margin-bottom: 20px;
    margin-left: 10px !important;
  }

  .loginstructorsbtn1 {
    width: 7rem;
    float: left;
    margin-bottom: 20px;
    margin-left: 10px !important;
  }

  .loginstructorsbtn2 {
    float: right;
    width: 8rem;
    margin-right: 10px !important;
  }

  input[type="radio"] {
    margin-left: 91px;
  }

  .mt-44 {
    margin-right: 18px;
  }

  .main_employeee input[type="text"] {
    width: 290px;
  }

  .main_employeee input[type="email"] {
    width: 290px;
  }

  .main_employeee input[type="number"] {
    width: 290px;
  }

  .main_employeee input[type="file"] {
    width: 290px;
  }

  .bg-35 .row img {
    margin-left: 6rem !important;
  }

  .main_employeeee select {
    width: 272px;
  }

  .faq h3 {
    margin-left: 117px !important;
  }

  .form1 {
    width: 282px;
  }

  .form1 input[type="email"] {
    margin-left: 10px;
    padding: 10px;
    margin-top: 10px;
    width: 245px;
  }

  .form1 textarea {
    width: 257px !important;
  }

  .form1 input[type="text"] {
    margin-left: 10px;
    padding: 10px;
    width: 245px;
  }

  .form1 input[type="number"] {
    margin-left: 10px;
    padding: 10px;
    width: 245px;
  }

  .col-md-5 img {
    width: 100% !important;
  }

  .comment textarea {
    width: 300px !important;
  }

  .bg img {
    display: none;
  }

  .col-md-10 img {
    width: 100%;
    height: 250px;
  }

  .gallery {
    margin-left: -24px !important;
  }

  .registration-container form {
    width: 274px !important;
  }

  .registration-container input {
    width: 260px !important;
    margin-left: 0px !important;
  }

  #Rutype {
    margin-left: 0px;
    width: 260px !important;
  }

  #Rcity {
    margin-left: 0px;
    width: 260px !important;
  }

  #Rdriving {
    margin-left: 0px;
    width: 260px !important;
  }

  #Rbutton {
    margin-left: 86px !important;
  }

  #sno {
    width: 260px !important;
    margin-left: 0px !important;
  }

  #cbtn2 {
    margin-left: 16px !important;
  }

  .h-001 .h-002 .h-004 .h-005 {
    display: flex;
    margin-left: -15px !important;
  }

  .h-003 img {
    width: 100% !important;
  }

  .ms-5 {
    width: 13rem !important;
  }

  .homeform {
    width: 30% !important;
    height: 571px !important;
  }

  .bg-25 img {
    height: 300px !important;
  }

  .proverb h1 {
    display: none;
  }

  .labels form {
    position: static !important;
    box-shadow: unset !important;
    border: none !important;
    margin-top: -4px;
    margin-left: unset;
  }

  .labels input[type="text"],
  .labels input[type="number"],
  .labels input[type="email"],
  #se_in {
    margin-left: 2px !important;
  }

  .labels input[type="text"],
  .labels input[type="email"] {
    width: 290px !important;
  }

  .labels select {
    margin-left: 0px !important;
    width: 290px !important;
  }

  .zxcvbnm {
    margin-left: -11px;
  }

  .bg-2 {
    width: 100%;
    margin-left: unset;
  }

  .bg-1 {
    margin-left: -112px !important;
    margin-top: -20px;
  }

  .bg_imgblue1 {
    position: absolute;
    top: 65rem !important;
    left: 94px !important;
  }

  .bg_imgblue img {
    position: relative;
    height: 604px;
  }

  .cent {
    position: absolute;
    top: 126rem !important;
    left: 0rem !important;
  }

  .offer {
    position: absolute;
    left: 0 !important;
    top: 133rem !important;
  }

  .best {
    margin-left: -34px;
    width: 119% !important;
    height: 63rem !important;
  }

  .bg_blue img {
    position: relative;
    width: 100%;
    height: 460px !important;
  }

  .display {
    position: absolute;
    top: 304rem !important;
  }

  .display h1 {
    color: yellow;
    text-align: center;
  }

  .display img {
    margin-left: 133px !important;
  }

  .content {
    position: absolute;
    top: 409rem !important;
    left: -6rem !important;
  }

  .bg-26,
  .bg-27 {
    width: 18rem !important;
    height: 11rem !important;
  }

  .bg-30 h2 {
    margin-top: 21px;
    margin-left: 55px;
    margin-bottom: -45px;
  }

  .bg-31,
  .bg-60 {
    margin-left: -94px !important;
  }

  .bg_blue1 {
    position: relative;
  }

  .bg_blue1 img {
    height: 1270px !important;
  }

  .map1 .card {
    width: 19rem !important;
    position: absolute;
    top: 473rem !important;
    left: 8px !important;
    margin-top: -100px;
  }

  .loc {
    position: absolute;
    top: 482rem !important;
    left: 8px !important;
  }

  .update {
    width: 334px;
  }

  .enform input[type="email"] {
    width: 281px !important;
    margin-left: 0px !important;
    padding: 10px;
    margin-top: 5px;
  }

  #name {
    width: 258px !important;
    margin-top: 10px;
  }

  .name2 {
    margin-left: 0px !important;
  }

  .loc .card {
    width: 19rem !important;
    margin-top: -70px;
  }

  .bg-32 {
    display: flex;

    flex-direction: column;
  }

  .loc .card h6 {
    display: flex;
    flex-direction: column;
  }

  .loc textarea {
    width: 16.1rem !important;
    height: 4rem;
  }

  .homebooknow {
    margin-left: 40px !important;
  }

  .about img {
    position: relative;
    /* margin-left: -19px; */
    width: 100% !important;
  }

  .homeform {
    width: 100% !important;
    height: 448px !important;
  }

  .best {
    position: static !important;
  }

  .bg-blue1 img {
    height: 79rem !important;
  }

  .h-001 .h-002 {
    display: flex;
    flex-direction: column;
  }

  .bg-14 {
    margin-left: 11rem;
    margin-top: -68px !important;
  }

  .bg-15 .card {
    width: 17rem !important;
    margin-left: -7px;
    margin-top: 10px;
  }

  .qwertyu .card {
    width: 15rem !important;
  }

  .rw1 {
    margin-top: -25px;
  }

  .bg-17 {
    display: none;
  }

  .rw2 {
    margin-top: 10px;
  }

  .asdfgh .card {
    margin-top: 10px;
  }

  .login-container form {
    height: 353px !important;
    width: 320px !important;
    margin-bottom: 20px;
    box-shadow: unset;
  }

  .login-container input[type="password"] {
    margin-left: 0px !important;
  }

  .login-container input[type="text"] {
    margin-left: 0px !important;
  }

  .cs {
    overflow: hidden;
    overflow-x: scroll;
  }

  .main_employeee form {
    width: unset;
    margin-left: -30px;
  }

  .timeadd {
    top: 44.7rem;
    left: 16rem;
    width: 4rem !important;
  }

  .timeinput {
    width: 211px;
  }

  .main_employeeee textarea {
    border: 1px solid black;
    width: 16.8rem;
  }

  .bg-35 {
    margin-left: -10px;
  }

  .bg-35 .row img {
    margin-left: 3rem !important;
  }

  #finame {
    width: 250px;
    padding: 10px;
  }

  .postblog {
    top: 317px !important;
    width: 297px !important;
    position: static !important;
  }

  #pf {
    margin-left: -2px !important;
    width: 277px !important;
  }

  #cat {
    margin-left: -2px !important;
    width: 277px !important;
  }

  .postblog input {
    margin-left: -2px !important;
    width: 277px !important;
  }

  #cross {
    margin-left: 92px !important;
    margin-top: -55px !important;
  }

  .enqform {
    width: 270px !important;
  }

  .enqlabel input[type="email"] {
    border: 1px solid black;
    margin-left: 13px;
    padding: 10px;
  }

  .enqlabel input[type="text"] {
    border: 1px solid black;
    margin-left: 12px !important;
    padding: 10px;
  }

  .enqbtn1 {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 424px) {
  .counts {
    margin-left: unset !important;
  }
  input[type="text"] {
    margin-left: unset !important;
    width: 17rem;
  }
  .timeslot {
    margin-top: -37px !important;
    margin-left: 251px !important;
  }
  .sidebar {
    top: 8.8rem !important;
  }
  .navbar1 {
    display: none !important;
  }
  .id_card_front {
    margin-left: -29px !important;
    float: unset !important;
    width: unset !important;
  }
  .id_background {
    height: unset !important;
  }
  .totalAmount {
    width: 271px;
  }
  .recomnded {
    position: absolute;
    padding: 5px;
    background-color: #ffc107;
    top: 13px;
    color: #fff;
    right: unset !important;
    transform: unset !important;
  }
  .staff_T {
    margin-top: -36px !important;
    margin-left: 112px !important;
  }
  .fghit_0 {
    /* margin-left: 25px !important; */
  }
  .customer-bg-img {
    height: 415px !important;
  }
  .customer-bg-img::before {
    content: "";
    background: rgba(22, 22, 22, 0.65) !important;
    position: absolute;
    /* top: 0; */
    left: 0;
    width: 100%;
    height: 415px !important;
    z-index: 0;
  }
  .reviews_cust {
    border: unset !important;
    border-radius: unset !important;
    padding: unset !important;
  }
  .card_courses {
    margin-left: 43px !important;
    width: 20rem !important;
  }
  .counts {
    transform: unset !important;
  }
  .counts p {
    color: #000;
  }
  .counts .center {
    margin: auto;
  }
  .dfghjkl_0 {
    display: none !important;
  }

  .dotted {
    border-right: unset !important;
  }

  #name1 {
    width: 300px !important;
    margin-left: 20px !important;
  }

  #name2 {
    width: 300px !important;
    margin-left: 20px !important;
  }

  #name3 {
    width: 300px !important;
    margin-left: 20px !important;
  }

  #Stextarea {
    margin-left: 23px !important;
    width: 328px !important;
  }

  #floatingTextarea2 {
    margin-left: -137px !important;
  }

  .main_employeeee form {
    width: 18rem !important;
  }

  #Sbutton {
    margin-left: 7rem !important;
  }

  .sdfjk_25 {
    flex-direction: column !important;
  }

  #Lbutton {
    margin-left: 116px !important;
  }

  #Rutype {
    margin-left: 0px !important;
    width: 260px !important;
  }

  #Rcity {
    margin-left: 0px !important;
    width: 260px !important;
  }

  #Rdriving {
    margin-left: 0px !important;
    width: 260px !important;
  }

  #Rbutton {
    margin-left: 86px !important;
  }

  .dfghio_098 {
    display: flex;
    flex-direction: column;
  }

  .asdfgh {
    margin-left: -10px !important;
  }

  .dhren {
    margin-top: 61px !important;
  }

  .xcvbnm_012 .row {
    margin-left: -10px;
  }

  .instructors_btn {
    margin-top: 62px;
  }

  .wertyuiop_67 {
    box-shadow: unset;
    border: unset;
  }

  .hjerwe {
    margin-left: unset !important;
  }

  .cbmd_09 {
    float: right;
    margin-top: -38px;
  }

  .modal-content {
    width: 100% !important;
  }

  .bholp_09 {
    margin-left: 0px !important;
  }

  .wfhid_09 {
    display: none !important;
  }

  .sdfghj_34 {
    height: 45rem !important;
  }

  .qwerty_0 {
    height: 473px !important;
  }

  .Home {
    height: 583px !important;
  }

  .Home1 {
    margin-left: 91px !important;
    width: auto !important;
  }

  .Home1 h2 {
    margin-left: -53px !important;
  }

  .findcourse {
    margin-left: 42px !important;
  }

  .sendrequest {
    margin-left: 80px !important;
  }

  .display1 {
    height: 615px !important;
  }

  .loca {
    display: none;
  }

  .loca1 {
    margin-left: 10rem;

    margin-top: -35px !important;
  }

  .loca1 button {
    width: 6rem !important;
  }

  .navbar-collapse {
    position: relative !important;
    z-index: 999 !important;
    background: white !important;
    top: 5px;

    color: black !important;
    width: 200px;
    left: -11.8px;
  }
  .counts_bg {
    height: 472px;
  }
  .navbar-collapse a {
    padding-left: 10px;
  }
  .login_navbar {
    margin-left: unset !important;
    margin-bottom: 10px !important;
  }
  .button_navbar {
    padding-left: unset !important;
  }
  .dropdown-menu[data-bs-popper] {
    left: unset !important;
  }

  .nav-link {
    color: black !important;
    margin-left: 20px;
  }

  .faq h3 {
    margin-left: 144px !important;
  }

  .form1 {
    width: 335px;
  }

  .form1 input[type="email"] {
    margin-left: 10px;
    padding: 10px;
    margin-top: 10px;
    width: 300px;
  }

  .form1 textarea {
    width: 314px !important;
  }

  .form1 input[type="text"] {
    margin-left: 10px;
    padding: 10px;
    width: 300px;
  }

  .col-md-5 img {
    width: 100% !important;
  }

  .comment textarea {
    width: 300px !important;
  }

  .bg img {
    display: none;
  }

  .postblog {
    position: static !important;
    width: 414px !important;
    margin-left: -6px;
  }

  #cross {
    margin-left: 39px;
  }

  .bg-22 input[type="file"] {
    width: 224px;
    margin-left: 3px;
  }

  .postblog input {
    width: 223px;
    padding: 10px;
    margin-top: 10px;
    background-color: whitesmoke;
    margin-left: 27px;
  }

  .bg-24 input {
    margin-left: 52px;
  }

  #pf {
    margin-left: 82px;
    width: 223px;
  }

  #cat {
    margin-left: 89px;
    width: 223px;
  }

  .col-md-10 img {
    width: 100%;
    height: 250px;
  }

  .name2 {
    margin-left: 0px !important;
  }

  .gallery {
    margin-left: 6px !important;
  }

  .registration-container form {
    width: 320px !important;
  }

  .registration-container input {
    width: 300px !important;
    margin-left: 0px !important;
  }

  #Rutype {
    margin-left: 0px;
    width: 300px !important;
  }

  #Rcity {
    margin-left: 5px;
    width: 295px !important;
  }

  #Rdriving {
    margin-left: 0px;
    width: 300px !important;
  }

  #Rbutton {
    margin-left: 86px !important;
  }

  #sno {
    width: 300px !important;
    margin-left: 0px !important;
  }

  .h-003 img {
    width: 100% !important;
  }

  .h-001 .h-002 .h-004 .h-005 {
    display: flex;
    margin-left: 2px !important;
  }

  .labels form {
    position: static !important;
    box-shadow: unset !important;
    border: none !important;
    margin-top: -4px;
    margin-left: unset;
  }

  .labels input[type="text"],
  .labels input[type="number"],
  .labels input[type="email"],
  #se_in {
    margin-left: 26px !important;
  }

  .labels input[type="text"],
  .labels input[type="email"] {
    width: 290px !important;
  }

  .labels select {
    margin-left: 25px !important;
    width: 290px !important;
  }

  .zxcvbnm {
    margin-left: -11px;
  }

  .bg-2 {
    width: 90%;
    margin-left: unset;
  }

  .bg-1 {
    margin-left: -112px !important;
    margin-top: -20px;
  }

  .bg_imgblue1 {
    position: absolute;
    top: 72rem !important;
    left: 94px !important;
  }

  .bg_imgblue img {
    position: relative;
    height: 604px;
  }

  /* .cent {
    position: absolute;
    top: 113rem !important;
    left: 1rem !important;
  } */

  .offer {
    margin-left: -54px !important;
    width: 350px !important;
  }

  .home_bg_about {
    height: unset !important;
  }
  .bg_blue img {
    position: relative;
    width: 100%;
    height: 460px !important;
  }

  .display {
    position: absolute;
    top: 304rem !important;
  }

  .display h1 {
    color: yellow;
    text-align: center;
  }

  .display img {
    margin-left: 137px !important;
  }

  .content {
    position: absolute;
    top: 409rem !important;
    left: -6rem !important;
  }

  .bg-26,
  .bg-27 {
    width: 21rem !important;
  }

  .bg-30 h2 {
    margin-top: 21px;
    margin-left: 55px;
    margin-bottom: -45px;
  }

  .bg-31,
  .bg-60 {
    margin-left: -94px !important;
  }

  .bg_blue1 {
    position: relative;
  }

  .bg_blue1 img {
    height: 1270px !important;
  }

  .map1 .card {
    width: 19rem !important;
    position: absolute;
    top: 445rem !important;
    left: 19px !important;
  }

  .loc {
    position: absolute;
    top: 455rem !important;
    left: 19px !important;
  }

  .loc .card {
    width: 19rem !important;
  }

  #name {
    width: 269px;
    padding: 10px;
    margin-top: 10px;
  }

  .bg-32 {
    display: flex;

    flex-direction: column;
  }

  .loc .card h6 {
    display: flex;
    flex-direction: column;
  }

  .loc textarea {
    width: 16.7rem !important;
    height: 4rem;
  }

  .homebooknow {
    margin-left: 40px !important;
  }

  .about img {
    position: relative;
    /* margin-left: -19px; */
    width: 100% !important;
  }

  .homeform {
    width: 100% !important;
  }

  .bg-blue1 img {
    height: 79rem !important;
  }

  .h-001 .h-002 {
    display: flex;
    flex-direction: column;
  }

  .bg-14 {
    margin-left: 15rem;
    margin-top: -64px !important;
  }

  .bg-15 .card {
    margin-top: 10px;
  }

  .bg-13 {
    margin-left: 10px;
  }

  .rw1 {
    margin-top: -25px;
  }

  .rw1 .col-lg-8 {
    margin-left: -10px;
  }

  .bg-17 {
    display: none;
  }

  .rw2 {
    margin-top: 10px;
  }

  .asdfgh .card {
    margin-top: 10px;
  }

  .login-container form {
    height: 353px !important;
    width: 320px !important;
    margin-bottom: 20px;
    box-shadow: unset;
  }

  .login-container input[type="password"] {
    margin-left: 0px !important;
  }

  .login-container input[type="text"] {
    margin-left: 0px !important;
  }

  .cs {
    overflow: hidden;
    overflow-x: scroll;
  }

  .main_employeee form {
    width: unset;
    margin-left: -30px;
  }

  .timeadd {
    top: 44.7rem;
    left: 16rem;
    width: 4rem !important;
  }

  .timeinput {
    width: 300px;
  }

  .main_employeeee textarea {
    border: 1px solid black;
    width: 18.8rem;
  }

  .enqform {
    width: 270px !important;
  }

  .enqlabel input[type="email"] {
    border: 1px solid black;
    margin-left: 13px;
    padding: 10px;
  }

  .enqlabel input[type="text"] {
    border: 1px solid black;
    margin-left: 12px !important;
    padding: 10px;
  }

  .enqbtn1 {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 425px) and (max-width: 480px) {
  .counts {
    margin-left: unset !important;
  }
  input[type="text"] {
    margin-left: unset !important;
    width: 17rem;
  }
  .timeslot {
    margin-top: -37px !important;
    margin-left: 251px !important;
  }
  .sidebar {
    top: 8.8rem !important;
  }
  .navbar1 {
    display: none !important;
  }
  .id_card_front {
    margin-left: -42px !important;
    float: unset !important;
  }
  .id_background {
    height: unset !important;
  }
  .totalAmount {
    width: 271px !important;
  }
  .recomnded {
    position: absolute;
    padding: 5px;
    background-color: #ffc107;
    top: 13px;
    color: #fff;
    right: unset !important;
    transform: unset !important;
  }
  .staff_T {
    margin-top: -36px !important;
    margin-left: 112px !important;
  }
  .fghit_0 {
    margin-left: 40px !important;
  }
  .customer-bg-img {
    height: 415px !important;
  }
  .customer-bg-img::before {
    content: "";
    background: rgba(22, 22, 22, 0.65) !important;
    position: absolute;
    /* top: 0; */
    left: 0;
    width: 100%;
    height: 415px !important;
    z-index: 0;
  }
  .reviews_cust {
    border: unset !important;
    border-radius: unset !important;
    padding: unset !important;
  }
  .card_courses {
    margin-left: 43px !important;
    width: 20rem !important;
  }
  .counts {
    transform: unset !important;
  }
  .counts p {
    color: #000;
  }
  .counts .center {
    margin: auto;
  }
  .dfghjkl_0 {
    display: none !important;
  }

  .dotted {
    border-right: unset !important;
  }

  #name1 {
    width: 300px !important;
    margin-left: 30px !important;
  }

  #name2 {
    width: 300px !important;
    margin-left: 30px !important;
  }

  #name3 {
    width: 300px !important;
    margin-left: 30px !important;
  }

  #Stextarea {
    margin-left: 23px !important;
    width: 367px !important;
  }

  .main_employeeee form {
    width: 18rem !important;
  }

  #Sbutton {
    margin-left: 7rem !important;
  }

  #Lbutton {
    margin-left: 116px !important;
  }

  .sdfjk_25 {
    display: flex;
    flex-direction: column;
  }

  .sdfgh_95 {
    display: flex;
    justify-content: center;
  }

  .wfhid_09 {
    display: none !important;
  }

  .dfghio_098 {
    display: flex;
    flex-direction: column;
  }

  .asdfgh {
    margin-left: -10px !important;
  }

  .dhren {
    margin-top: 61px !important;
  }

  .xcvbnm_012 .row {
    margin-left: -10px;
  }

  .instructors_btn {
    margin-top: 62px;
  }

  .wertyuiop_67 {
    box-shadow: unset;
    border: unset;
  }

  .hjerwe {
    margin-left: unset !important;
  }

  .cbmd_09 {
    float: right;
    margin-top: -38px;
  }

  .modal-content {
    width: 100% !important;
  }

  .sdfghj_34 {
    height: 43rem !important;
  }

  .qwerty_0 {
    height: 473px !important;
  }

  .Home {
    height: 550px !important;
  }

  .Home1 {
    margin-left: 91px !important;
    width: auto !important;
  }

  .Home1 h2 {
    margin-left: -53px !important;
  }

  .findcourse {
    margin-left: 42px !important;
  }

  .sendrequest {
    margin-left: 100px !important;
  }

  .display1 {
    height: 615px !important;
  }

  .findcourse {
    margin-left: 79px !important;
  }

  .loca {
    display: none;
  }

  .loca1 {
    margin-left: 10rem;

    margin-top: -35px !important;
  }

  .loca1 button {
    width: 9rem !important;
    margin-left: 85px !important;
  }

  #name {
    width: 300px;
    padding: 10px;
    margin-top: 10px;
  }

  .name2 {
    margin-left: 0px !important;
  }

  .navbar-collapse {
    position: relative !important;
    z-index: 999 !important;
    background: white !important;
    top: 5px;

    color: black !important;
    width: 200px;
    left: -11.8px;
  }
  .counts_bg {
    height: 472px;
  }
  .navbar-collapse a {
    padding-left: 10px;
  }
  .login_navbar {
    margin-left: unset !important;
    margin-bottom: 10px !important;
  }
  .button_navbar {
    padding-left: unset !important;
  }
  .dropdown-menu[data-bs-popper] {
    left: unset !important;
  }
  /* .navbar-toggler {
    position: absolute;
    left: 9px;
  }

  /* .nav-link {
    color: black !important;
    margin-left: 20px;
  } */

  .faq h3 {
    margin-left: 160px !important;
  }

  .form1 {
    width: 379px;
  }

  .form1 input[type="email"] {
    margin-left: 10px;
    padding: 10px;
    margin-top: 10px;
    width: 300px;
  }

  .form1 textarea {
    width: 357px !important;
  }

  .form1 input[type="text"] {
    margin-left: 10px;
    padding: 10px;
    width: 300px;
  }

  .col-md-5 img {
    width: 100% !important;
  }

  .comment textarea {
    width: 300px !important;
  }

  .bg img {
    display: none;
  }

  .postblog {
    position: static !important;
    width: 414px !important;
    margin-left: 17px;
  }

  #cross {
    margin-left: 39px;
  }

  .bg-22 input[type="file"] {
    width: 224px;
    margin-left: 3px;
  }

  .postblog input {
    width: 223px;
    padding: 10px;
    margin-top: 10px;
    background-color: whitesmoke;
    margin-left: 27px;
  }

  .bg-24 input {
    margin-left: 52px;
  }

  #pf {
    margin-left: 82px;
    width: 223px;
  }

  #cat {
    margin-left: 89px;
    width: 223px;
  }

  .col-md-10 img {
    width: 100%;
    height: 280px;
  }

  .registration-container form {
    width: 368px !important;
  }

  .registration-container input {
    width: 319px !important;
    margin-left: 8px !important;
  }

  #Rutype {
    margin-left: 4px !important;
    width: 324px !important;
  }

  #Rcity {
    margin-left: 13px !important;
    width: 312px !important;
  }

  #Rdriving {
    margin-left: 10px !important;
    width: 314px !important;
  }

  #Rbutton {
    margin-left: 124px !important;
  }

  #sno {
    width: 330px !important;
    margin-left: 0px !important;
  }

  .bg-25 img {
    height: 300px !important;
  }

  .labels form {
    position: static !important;
    box-shadow: unset !important;
    border: none !important;
    margin-top: -4px;
    margin-left: unset;
  }

  .labels input[type="text"],
  .labels input[type="email"] {
    width: 290px !important;
    margin-left: 46px !important;
  }

  .labels select {
    margin-left: 45px !important;
    width: 290px !important;
  }

  .zxcvbnm {
    margin-left: -11px;
  }

  .bg-2 {
    width: 88%;
    margin-left: unset;
  }

  .bg-1 {
    margin-left: -112px !important;
    margin-top: -20px;
  }

  .bg_imgblue1 {
    position: absolute;
    top: 62rem !important;
    left: 104px !important;
  }

  .bg_imgblue img {
    position: relative;
    height: 604px;
  }

  .home_bg_about {
    height: unset !important;
  }
  .graph {
    height: 300px !important;
  }
  .offer {
    margin-left: -51px;
  }

  .bg_blue img {
    position: relative;
    width: 100%;
    height: 460px !important;
  }

  .display {
    position: absolute;
    top: 287rem !important;
  }

  .display h1 {
    color: yellow;
    text-align: center;
  }

  .display img {
    margin-left: 180px !important;
  }

  .content {
    position: absolute;
    top: 409rem !important;
    left: -6rem !important;
  }

  .bg-26,
  .bg-27 {
    width: 24rem !important;
  }

  .bg-30 h2 {
    margin-top: 21px;
    margin-left: 55px;
    margin-bottom: -45px;
  }

  .bg-31,
  .bg-60 {
    margin-left: -94px !important;
  }

  .bg_blue1 {
    position: relative;
  }

  .bg_blue1 img {
    height: 1270px !important;
  }

  .map1 .card {
    width: 19rem !important;
    position: absolute;
    top: 444rem !important;
    left: 10px !important;
  }

  .loc {
    position: absolute;
    top: 451rem !important;
    left: 11px !important;
  }

  .loc .card {
    width: 23rem !important;
  }

  .bg-32 {
    display: flex;

    flex-direction: column;
  }

  .loc .card h6 {
    display: flex;
    flex-direction: column;
  }

  .loc textarea {
    width: 18.7rem !important;
    height: 4rem;
  }

  .homebooknow {
    margin-left: 40px !important;
  }

  .about img {
    position: relative;
    /* margin-left: -19px; */
    width: 100% !important;
  }

  .homeform {
    width: 100% !important;
  }

  .best {
    position: static !important;
    margin-top: 10px;
    width: unset !important;
    height: unset !important;
  }

  .bg-blue1 img {
    height: 79rem !important;
  }

  .h-001 .h-002 {
    display: flex;
    flex-direction: column;
  }

  .bg-14 {
    margin-left: 15rem;
    margin-top: -63px !important;
  }

  .bg-15 .card {
    margin-top: 10px;
  }

  .bg-13 {
    margin-left: 10px;
  }

  .rw .btn {
    margin-left: 108px !important;
  }

  .rw1 {
    margin-top: -25px;
  }

  .rw1 .col-lg-8 {
    margin-left: -10px;
  }

  .bg-17 {
    display: none;
  }

  .rw2 {
    margin-top: 10px;
  }

  .asdfgh .card {
    margin-top: 10px;
  }

  .login-container form {
    height: 353px !important;
    width: 320px !important;
    margin-bottom: 20px;
    box-shadow: unset;
  }

  .login-container input[type="password"] {
    margin-left: 0px !important;
  }

  .login-container input[type="text"] {
    margin-left: 0px !important;
  }

  .cs {
    overflow: hidden;
    overflow-x: scroll;
  }

  .main_employeee form {
    width: unset;
    margin-left: -30px;
  }

  .timeadd {
    top: 44.7rem;
    left: 16rem;
    width: 4rem !important;
  }

  .timeinput {
    width: 300px;
  }

  .main_employeeee textarea {
    border: 1px solid black;
    width: 18.8rem;
  }
}

@media only screen and (min-width: 481px) and (max-width: 600px) {
  .counts {
    margin-left: unset !important;
  }
  input[type="text"] {
    margin-left: unset !important;
    width: 17rem;
  }
  .timeslot {
    margin-top: -37px !important;
    margin-left: 251px !important;
  }
  .sidebar {
    top: 8.8rem !important;
  }
  .navbar1 {
    display: none !important;
  }
  .id_card_front {
    margin-left: -42px !important;
    float: unset !important;
  }
  .id_background {
    height: unset !important;
  }
  .totalAmount {
    width: 271px !important;
  }
  .recomnded {
    position: absolute;
    padding: 5px;
    background-color: #ffc107;
    top: 13px;
    color: #fff;
    right: unset !important;
    transform: unset !important;
  }
  .fghit_0 {
    margin-left: 40px !important;
  }
  .counts {
    transform: unset !important;
  }
  .dfghjkl_0 {
    display: none !important;
  }

  .dotted {
    border-right: unset !important;
    padding-right: unset !important;
  }

  #name1 {
    width: 274px !important;
    margin-left: -1px !important;
  }

  #name2 {
    width: 274px !important;
    margin-left: 5px !important;
  }

  #name3 {
    width: 274px !important;
    margin-left: -1px !important;
  }

  #Stextarea {
    margin-left: 27px !important;
    width: 400px !important;
  }

  .main_employeeee form {
    width: 18rem !important;
  }

  #Sbutton {
    margin-left: 7rem !important;
  }

  #Lbutton {
    margin-left: 116px !important;
  }

  .sdfjk_25 {
    flex-direction: column !important;
  }

  .dfghio_098 {
    display: flex;
    flex-direction: column;
  }

  .asdfgh {
    margin-left: -10px !important;
  }

  .dhren {
    margin-top: 61px !important;
  }

  .xcvbnm_012 .row {
    margin-left: -10px;
  }

  .instructors_btn {
    margin-top: 62px;
  }

  .wertyuiop_67 {
    box-shadow: unset;
    border: unset;
  }

  .hjerwe {
    margin-left: unset !important;
  }

  .cbmd_09 {
    float: right;
    margin-top: -38px;
  }

  .container .qazxs_09 {
    margin-left: 70px;
  }

  .modal-content {
    width: 100% !important;
  }

  .bholp_09 {
    margin-left: 0px !important;
  }

  .wfhid_09 {
    display: none !important;
  }

  .sdfghj_34 {
    height: 38rem !important;
  }

  .qwerty_0 {
    height: 473px !important;
  }

  .Home {
    height: 550px !important;
  }

  .Home1 {
    margin-left: 91px !important;
    width: auto !important;
  }

  .Home1 h2 {
    margin-left: -53px !important;
  }

  .findcourse {
    margin-left: 42px !important;
  }

  .sendrequest {
    margin-left: 150px !important;
  }

  .display1 {
    height: 615px !important;
  }

  .findcourse {
    margin-left: 79px !important;
  }

  .loca {
    display: none;
  }

  .loca1 {
    margin-left: 10rem;

    margin-top: -35px !important;
  }

  .loca1 button {
    width: 6rem !important;
  }

  .name2 {
    margin-left: 0px !important;
    margin-top: 10px;
  }

  .navbar-collapse {
    position: relative !important;
    z-index: 999 !important;
    background: white !important;
    top: 5px;

    color: black !important;
    width: 200px;
    left: -11.8px;
  }
  .counts_bg {
    height: 472px;
  }
  .navbar-collapse a {
    padding-left: 10px;
  }
  .login_navbar {
    margin-left: unset !important;
    margin-bottom: 10px !important;
  }
  .button_navbar {
    padding-left: unset !important;
  }
  .dropdown-menu[data-bs-popper] {
    left: unset !important;
  }
  .nav-link {
    color: black !important;
    margin-left: 20px;
  }

  .form1 {
    width: 379px;
  }

  .form1 input[type="email"] {
    margin-left: 10px;
    padding: 10px;
    margin-top: 10px;
    width: 300px;
  }

  .form1 textarea {
    width: 357px !important;
  }

  .form1 input[type="text"] {
    margin-left: 10px;
    padding: 10px;
    width: 300px;
  }

  .col-md-5 img {
    width: 100% !important;
  }

  .comment textarea {
    width: 300px !important;
  }

  .bg img {
    display: none;
  }

  .postblog {
    position: static !important;
    width: 414px !important;
    margin-left: 17px;
  }

  #cross {
    margin-left: 39px;
  }

  .bg-22 input[type="file"] {
    width: 224px;
    margin-left: 3px;
  }

  .postblog input {
    width: 223px;
    padding: 10px;
    margin-top: 10px;
    background-color: whitesmoke;
    margin-left: 27px;
  }

  .bg-24 input {
    margin-left: 52px;
  }

  #pf {
    margin-left: 82px;
    width: 223px;
  }

  #cat {
    margin-left: 89px;
    width: 223px;
  }

  .col-md-10 img {
    width: 100%;
    height: 300px;
  }

  .gallery {
    margin-left: 0px !important;
  }

  .gallery h4 {
    margin-left: -157px !important;
  }

  .registration-container form {
    width: 368px !important;
  }

  .registration-container input {
    width: 330px !important;
    margin-left: 0px !important;
  }

  #Rutype {
    margin-left: 0px !important;
    width: 328px !important;
  }

  #Rcity {
    margin-left: 0px !important;
    width: 328px !important;
  }

  #Rdriving {
    margin-left: 0px !important;
    width: 328px !important;
  }

  #Rbutton {
    margin-left: 119px !important;
  }

  #sno {
    width: 330px !important;
    margin-left: 0px !important;
  }

  .bg-25 img {
    height: 300px !important;
  }

  .labels form {
    position: static !important;
    box-shadow: unset !important;
    border: none !important;
    margin-top: -4px;
    margin-left: unset;
  }

  .labels input[type="text"],
  .labels input[type="email"] {
    width: 290px !important;
    margin-left: 42px !important;
  }

  .labels select {
    width: 290px !important;
    margin-left: 42px !important;
  }

  .zxcvbnm {
    margin-left: -11px;
  }

  .bg-2 {
    width: 100%;
    margin-left: unset;
  }

  .bg-1 {
    margin-left: -112px !important;
    margin-top: -20px;
  }

  .bg_imgblue1 {
    position: absolute;
    top: 87rem !important;
    left: 126px !important;
  }

  .bg_imgblue img {
    position: relative;
    height: 604px;
  }

  .cent {
    position: absolute;
    top: 104rem !important;
    left: 5rem !important;
  }

  .offer {
    position: absolute;
    left: 51px !important;
    top: 115rem !important;
  }

  .best {
    margin-left: -34px;
    width: 113% !important;
    height: 43rem !important;
  }

  .bg_blue img {
    position: relative;
    width: 100%;
    height: 460px !important;
  }

  .display {
    position: absolute;
    top: 304rem !important;
  }

  .display h1 {
    color: yellow;
    text-align: center;
  }

  .display img {
    margin-left: 189px !important;
  }

  .content {
    position: absolute;
    top: 409rem !important;
    left: -6rem !important;
  }

  .bg-26,
  .bg-27 {
    width: 28rem !important;
  }

  .bg-30 h2 {
    margin-top: 21px;
    margin-left: 55px;
    margin-bottom: -45px;
  }

  .bg-31,
  .bg-60 {
    margin-left: -94px !important;
  }

  .bg_blue1 {
    position: relative;
  }

  .bg_blue1 img {
    height: 1270px !important;
  }

  .map1 .card {
    width: 19rem !important;
    position: absolute;
    top: 428rem !important;
    left: 51px !important;
  }

  .loc {
    position: absolute;
    top: 438rem !important;
    left: 48px !important;
  }

  .loc .card {
    width: 24rem !important;
  }

  .bg-32 {
    display: flex;
    flex-direction: column;
  }

  .loc .card h6 {
    display: flex;
    flex-direction: column;
  }

  .loc textarea {
    margin-left: -1px !important;
    width: 20.7rem !important;
    height: 4rem;
  }

  .homebooknow {
    margin-left: 40px !important;
  }

  .about img {
    position: relative;
    margin-left: -19px;
    width: 100% !important;
  }

  .homeform {
    width: 101% !important;
    height: 410px !important;
  }

  .best {
    position: static !important;
  }

  .bg-blue1 img {
    height: 79rem !important;
  }

  .h-001 .h-002 {
    display: flex;
    flex-direction: column;
  }

  .bg-14 {
    margin-left: 21rem;
    margin-top: -64px !important;
  }

  .bg-15 .card {
    margin-top: 10px;
  }

  .bg-13 {
    margin-left: 10px;
  }

  .rw1 {
    margin-top: -25px;
  }

  .rw1 .col-lg-8 {
    margin-left: -10px;
  }

  .bg-17 {
    display: none;
  }

  .rw2 {
    margin-top: 10px;
  }

  .bg-11 {
    overflow: hidden;
    overflow-x: scroll;
  }

  .asdfgh .card {
    margin-top: 10px;
  }

  .login-container form {
    height: 353px !important;
    width: 320px !important;
    margin-bottom: 20px;
    box-shadow: unset;
  }

  .login-container input[type="password"] {
    margin-left: 0px !important;
  }

  .login-container input[type="text"] {
    margin-left: 0px !important;
  }

  .cs {
    overflow: hidden;
    overflow-x: scroll;
  }

  .main_employeee form {
    width: unset;
    margin-left: -30px;
  }

  .timeadd {
    /* position: absolute; */
    top: 44.7rem;
    left: 16rem;
    width: 4rem !important;
  }

  .timeinput {
    /* margin-left: -18px !important; */
    width: 386px;
  }

  .main_employeeee select {
    width: 386px;
  }

  .main_employeeee textarea {
    border: 1px solid black;
    width: 24rem;
  }
}

@media only screen and (min-width: 601px) and (max-width: 767px) {
  .id_card_front {
    margin-left: -42px !important;
    float: unset !important;
  }
  .id_background {
    height: unset !important;
  }
  .totalAmount {
    width: 271px !important;
  }
  .recomnded {
    position: absolute;
    padding: 5px;
    background-color: #ffc107;
    top: 13px;
    color: #fff;
    right: unset !important;
    transform: unset !important;
  }
  .counts {
    transform: unset !important;
  }
  .bnmjhi_0 .card {
    /* max-width: unset !important; */
  }

  .homeform {
    width: 51% !important;
    height: 410px !important;
    position: absolute !important;
  }

  #imagee {
    width: 40px !important;
  }

  .dfghjkl_0 {
    display: none !important;
  }

  .dotted {
    border-right: unset !important;
    padding-right: unset !important;
  }

  #name1 {
    width: 274px !important;
    margin-left: -1px !important;
  }

  #name2 {
    width: 274px !important;
    margin-left: 5px !important;
  }

  #name3 {
    width: 274px !important;
    margin-left: -1px !important;
  }

  #Stextarea {
    margin-left: 146px !important;
    width: 400px !important;
  }

  .main_employeeee form {
    width: 18rem !important;
  }

  #Sbutton {
    margin-left: 10rem !important;
  }

  #Lbutton {
    margin-left: 116px !important;
  }

  .sdfjk_25 {
    flex-direction: column !important;
  }

  .dfghio_098 {
    display: flex;
    flex-direction: column;
  }

  .asdfgh {
    margin-left: -10px !important;
  }

  .dhren {
    margin-top: 61px !important;
  }

  .xcvbnm_012 .row {
    margin-left: -10px;
  }

  .instructors_btn {
    margin-top: 62px;
  }

  .wertyuiop_67 {
    box-shadow: unset;
    border: unset;
  }

  .hjerwe {
    margin-left: unset !important;
  }

  .cbmd_09 {
    float: right;
    /* margin-top: -38px; */
  }

  .container .qazxs_09 {
    margin-left: 70px;
  }

  .modal-content {
    width: 100% !important;
  }

  .bholp_09 {
    margin-left: 0px !important;
  }

  .wfhid_09 {
    display: none !important;
  }

  .sdfghj_34 {
    height: 37rem !important;
  }

  .qwerty_0 {
    height: 473px !important;
  }

  .Home {
    height: 550px !important;
  }

  .Home1 {
    margin-left: 91px !important;
    width: auto !important;
  }

  .Home1 h2 {
    margin-left: -53px !important;
  }

  .findcourse {
    margin-left: 42px !important;
  }

  .sendrequest {
    margin-left: 150px !important;
  }

  .display1 {
    height: 615px !important;
  }

  .findcourse {
    margin-left: 79px !important;
  }

  .loca {
    display: none;
  }

  .loca1 {
    margin-left: 10rem;

    margin-top: -35px !important;
  }

  .loca1 button {
    width: 6rem !important;
    margin-top: -7px !important;
    margin-left: 440px !important;
  }

  .name2 {
    margin-left: 0px !important;
    margin-top: 10px;
  }

  .navbar-collapse {
    position: relative !important;
    z-index: 999 !important;
    background: white !important;
    top: 5px;

    color: black !important;
    width: 200px;
    left: -11.8px;
  }
  .counts_bg {
    height: 472px;
  }
  .navbar-collapse a {
    padding-left: 10px;
  }
  .login_navbar {
    margin-left: unset !important;
    margin-bottom: 10px !important;
  }
  .button_navbar {
    padding-left: unset !important;
  }
  .dropdown-menu[data-bs-popper] {
    left: unset !important;
  }
  .nav-link {
    color: black !important;
    margin-left: 20px;
  }

  .form1 {
    width: 352px;
  }

  .form1 input[type="email"] {
    margin-left: 10px;
    padding: 10px;
    margin-top: 10px;
    width: 300px;
  }

  .form1 textarea {
    width: 328px !important;
  }

  .form1 input[type="text"] {
    margin-left: 10px;
    padding: 10px;
    width: 300px;
  }

  .col-md-5 img {
    width: 100% !important;
  }

  .comment textarea {
    width: 300px !important;
  }

  .bg img {
    display: none;
  }

  .postblog {
    position: static !important;
    width: 414px !important;
    margin-left: 17px;
  }

  #cross {
    margin-left: 39px;
  }

  .bg-22 input[type="file"] {
    width: 224px;
    margin-left: 3px;
  }

  .postblog input {
    width: 223px;
    padding: 10px;
    margin-top: 10px;
    background-color: whitesmoke;
    margin-left: 27px;
  }

  .bg-24 input {
    margin-left: 52px;
  }

  #pf {
    margin-left: 82px;
    width: 223px;
  }

  #cat {
    margin-left: 89px;
    width: 223px;
  }

  .col-md-10 img {
    width: 100%;
    height: 300px;
  }

  .gallery {
    margin-left: 0px !important;
  }

  .gallery h4 {
    margin-left: -157px !important;
  }

  .registration-container form {
    width: 368px !important;
  }

  .registration-container input {
    width: 330px !important;
    margin-left: 0px !important;
  }

  #Rutype {
    margin-left: 0px !important;
    width: 328px !important;
  }

  #Rcity {
    margin-left: 0px !important;
    width: 328px !important;
  }

  #Rdriving {
    margin-left: 0px !important;
    width: 328px !important;
  }

  #Rbutton {
    margin-left: 119px !important;
  }

  #sno {
    width: 330px !important;
    margin-left: 0px !important;
  }

  .bg-25 img {
    height: 300px !important;
  }

  .labels form {
    /* position: static !important; */
    box-shadow: unset !important;
    border: none !important;
    margin-top: -4px;
    margin-left: unset;
  }

  .labels input[type="text"],
  .labels input[type="email"] {
    width: 290px !important;
    margin-left: 42px !important;
  }

  .labels select {
    width: 290px !important;
    margin-left: 42px !important;
  }

  .zxcvbnm {
    margin-left: -11px;
  }

  .bg-2 {
    width: 100%;
    margin-left: unset;
  }

  .bg-1 {
    margin-left: -112px !important;
    margin-top: -20px;
  }

  .bg_imgblue1 {
    position: absolute;
    top: 87rem !important;
    left: 126px !important;
  }

  .bg_imgblue img {
    position: relative;
    height: 604px;
  }

  .cent {
    position: absolute;
    top: 104rem !important;
    left: 5rem !important;
  }

  .offer {
    position: absolute;
    left: 51px !important;
    top: 115rem !important;
  }

  .best {
    margin-left: -34px;
    width: 113% !important;
    height: 43rem !important;
  }

  .bg_blue img {
    position: relative;
    width: 100%;
    height: 460px !important;
  }

  .display {
    position: absolute;
    top: 304rem !important;
  }

  .display h1 {
    color: yellow;
    text-align: center;
  }

  .display img {
    margin-left: 189px !important;
  }

  .content {
    position: absolute;
    top: 409rem !important;
    left: -6rem !important;
  }

  .bg-26,
  .bg-27 {
    width: 13.5rem !important;
  }

  .bg-30 h2 {
    margin-top: 21px;
    margin-left: 55px;
    margin-bottom: -45px;
  }

  .bg-31,
  .bg-60 {
    margin-left: -94px !important;
  }

  .bg_blue1 {
    position: relative;
  }

  .bg_blue1 img {
    height: 1270px !important;
  }

  .map1 .card {
    width: 19rem !important;
    position: absolute;
    top: 428rem !important;
    left: 51px !important;
  }

  .loc {
    position: absolute;
    top: 342rem !important;
    left: 48px !important;
  }

  .loc .card {
    width: 24rem !important;
  }

  .bg-32 {
    display: flex;
    flex-direction: column;
  }

  .loc .card h6 {
    display: flex;
    flex-direction: column;
  }

  .loc textarea {
    margin-left: -1px !important;
    width: 20.7rem !important;
    height: 4rem;
  }

  .homebooknow {
    margin-left: 40px !important;
  }

  .about img {
    position: relative;
    margin-left: -19px;
    width: 100% !important;
  }

  /* .homeform {
        width: 101% !important;
        height: 410px !important;
    } */

  .best {
    position: static !important;
  }

  .bg-blue1 img {
    height: 79rem !important;
  }

  .h-001 .h-002 {
    display: flex;
    flex-direction: column;
  }

  .bg-14 {
    margin-left: 21rem;
    margin-top: -64px !important;
  }

  .bg-15 .card {
    margin-top: 10px;
  }

  .bg-13 {
    margin-left: 10px;
  }

  .rw1 {
    margin-top: -25px;
  }

  .rw1 .col-lg-8 {
    margin-left: -10px;
  }

  .bg-17 {
    display: none;
  }

  .rw2 {
    margin-top: 10px;
  }

  /* .bg-11 {
        overflow: hidden;
        overflow-x: scroll;
    } */

  .asdfgh .card {
    margin-top: 10px;
  }

  .login-container form {
    height: 353px !important;
    width: 320px !important;
    margin-bottom: 20px;
    box-shadow: unset;
  }

  .login-container input[type="password"] {
    margin-left: 0px !important;
  }

  .login-container input[type="text"] {
    margin-left: 0px !important;
  }

  .cs {
    overflow: hidden;
    overflow-x: scroll;
  }

  .main_employeee form {
    width: unset;
    margin-left: -30px;
  }

  .timeadd {
    /* position: absolute; */
    top: 44.7rem;
    left: 16rem;
    width: 4rem !important;
  }

  .timeinput {
    /* margin-left: -18px !important; */
    width: 386px;
  }

  .main_employeeee select {
    width: 386px;
  }

  .main_employeeee textarea {
    border: 1px solid black;
    width: 24rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .id_card_front {
    margin-left: -42px !important;
    float: unset !important;
  }
  .id_background {
    height: unset !important;
  }
  .totalAmount {
    width: 271px !important;
  }
  .recomnded {
    position: absolute;
    padding: 5px;
    background-color: #ffc107;
    top: 13px;
    color: #fff;
    right: unset !important;
    transform: unset !important;
  }
  .bnjkhgf_0 .card {
    width: 90% !important;
  }

  .homeform {
    width: 42% !important;
    height: 410px !important;
    position: absolute !important;
  }

  #imagee {
    width: 40px !important;
  }

  .dfghjkl_0 {
    display: none !important;
  }

  .dotted {
    border-right: unset !important;
    padding-right: unset !important;
  }

  #name1 {
    width: 274px !important;
    margin-left: -1px !important;
  }

  #name2 {
    width: 274px !important;
    margin-left: 5px !important;
  }

  #name3 {
    width: 274px !important;
    margin-left: -1px !important;
  }

  #Stextarea {
    margin-left: 146px !important;
    width: 400px !important;
  }

  .main_employeeee form {
    width: 18rem !important;
  }

  #Sbutton {
    margin-left: 10rem !important;
  }

  #Lbutton {
    margin-left: 116px !important;
  }

  .sdfjk_25 {
    flex-direction: column !important;
  }

  .dfghio_098 {
    display: flex;
    flex-direction: column;
  }

  .asdfgh {
    margin-left: -10px !important;
  }

  .dhren {
    margin-top: 61px !important;
  }

  .xcvbnm_012 .row {
    margin-left: -10px;
  }

  .instructors_btn {
    margin-top: 62px;
  }

  .wertyuiop_67 {
    box-shadow: unset;
    border: unset;
  }

  .hjerwe {
    margin-left: unset !important;
  }

  .cbmd_09 {
    float: right;
    /* margin-top: -38px; */
  }

  .container .qazxs_09 {
    margin-left: 70px;
  }

  .modal-content {
    width: 100% !important;
  }

  .bholp_09 {
    margin-left: 0px !important;
  }

  .wfhid_09 {
    display: none !important;
  }

  .sdfghj_34 {
    height: 37rem !important;
  }

  .qwerty_0 {
    height: 473px !important;
  }

  .Home {
    height: 550px !important;
  }

  .Home1 {
    margin-left: 91px !important;
    width: auto !important;
  }

  .Home1 h2 {
    margin-left: -53px !important;
  }

  .findcourse {
    margin-left: 42px !important;
  }

  .sendrequest {
    margin-left: 150px !important;
  }

  .display1 {
    height: 615px !important;
  }

  .findcourse {
    margin-left: 79px !important;
  }

  .loca {
    display: none;
  }

  .loca1 {
    margin-left: 10rem;

    margin-top: -35px !important;
  }

  .loca1 button {
    width: 6rem !important;
    margin-top: 43px !important;
    margin-left: 22px !important;
  }

  .name2 {
    margin-left: 0px !important;
    margin-top: 10px;
  }

  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    position: relative !important;
    z-index: 999 !important;
    background: white !important;
    top: 40px;

    color: black !important;
    width: 300px;
    left: -132px;
  }

  .navbar-toggler {
    position: absolute;
    left: 9px;
  }

  .nav-link {
    color: black !important;
    margin-left: 20px;
  }

  .form1 {
    width: 352px;
  }

  .form1 input[type="email"] {
    margin-left: 10px;
    padding: 10px;
    margin-top: 10px;
    width: 300px;
  }

  .form1 textarea {
    width: 328px !important;
  }

  .form1 input[type="text"] {
    margin-left: 10px;
    padding: 10px;
    width: 300px;
  }

  .col-md-5 img {
    width: 100% !important;
  }

  .comment textarea {
    width: 300px !important;
  }

  .bg img {
    display: none;
  }

  .postblog {
    position: static !important;
    width: 414px !important;
    margin-left: 17px;
  }

  #cross {
    margin-left: 39px;
  }

  .bg-22 input[type="file"] {
    width: 224px;
    margin-left: 3px;
  }

  .postblog input {
    width: 223px;
    padding: 10px;
    margin-top: 10px;
    background-color: whitesmoke;
    margin-left: 27px;
  }

  .bg-24 input {
    margin-left: 52px;
  }

  #pf {
    margin-left: 82px;
    width: 223px;
  }

  #cat {
    margin-left: 89px;
    width: 223px;
  }

  .col-md-10 img {
    width: 100%;
    height: 300px;
  }

  .gallery {
    margin-left: 0px !important;
  }

  .gallery h4 {
    margin-left: -157px !important;
  }

  .registration-container form {
    width: 368px !important;
  }

  .registration-container input {
    width: 330px !important;
    margin-left: 0px !important;
  }

  #Rutype {
    margin-left: 0px !important;
    width: 328px !important;
  }

  #Rcity {
    margin-left: 0px !important;
    width: 328px !important;
  }

  #Rdriving {
    margin-left: 0px !important;
    width: 328px !important;
  }

  #Rbutton {
    margin-left: 119px !important;
  }

  #sno {
    width: 330px !important;
    margin-left: 0px !important;
  }

  .bg-25 img {
    height: 300px !important;
  }

  .labels form {
    /* position: static !important; */
    box-shadow: unset !important;
    border: none !important;
    margin-top: -4px;
    margin-left: unset;
  }

  .labels input[type="text"],
  .labels input[type="email"] {
    width: 290px !important;
    margin-left: 42px !important;
  }

  .labels select {
    width: 290px !important;
    margin-left: 42px !important;
  }

  .zxcvbnm {
    margin-left: -11px;
  }

  .bg-2 {
    width: 100%;
    margin-left: unset;
  }

  .bg-1 {
    margin-left: -112px !important;
    margin-top: -20px;
  }

  .bg_imgblue1 {
    position: absolute;
    top: 87rem !important;
    left: 126px !important;
  }

  .bg_imgblue img {
    position: relative;
    height: 604px;
  }

  .cent {
    position: absolute;
    top: 104rem !important;
    left: 5rem !important;
  }

  .offer {
    position: absolute;
    left: 51px !important;
    top: 115rem !important;
  }

  .best {
    margin-left: -34px;
    width: 113% !important;
    height: 43rem !important;
  }

  .bg_blue img {
    position: relative;
    width: 100%;
    height: 460px !important;
  }

  .display {
    position: absolute;
    top: 304rem !important;
  }

  .display h1 {
    color: yellow;
    text-align: center;
  }

  .display img {
    margin-left: 189px !important;
  }

  .content {
    position: absolute;
    top: 409rem !important;
    left: -6rem !important;
  }

  .bg-26,
  .bg-27 {
    width: 15rem !important;
    margin-left: 40px !important;
  }

  .bg-26,
  .bg-27 .card-header {
    font-size: 14px;
  }
  #c-card {
    width: 15rem !important;
    margin-left: 40px !important;
  }

  .bg-30 h2 {
    margin-top: 21px;
    margin-left: 55px;
    margin-bottom: -45px;
  }

  .bg-31,
  .bg-60 {
    margin-left: -94px !important;
  }

  .bg_blue1 {
    position: relative;
  }

  .bg_blue1 img {
    height: 1270px !important;
  }

  .map1 .card {
    width: 19rem !important;
    position: absolute;
    top: 428rem !important;
    left: 51px !important;
  }

  .loc {
    position: absolute;
    top: 342rem !important;
    left: 48px !important;
  }

  .loc .card {
    width: 24rem !important;
  }

  .bg-32 {
    display: flex;
    flex-direction: column;
  }

  .loc .card h6 {
    display: flex;
    flex-direction: column;
  }

  .loc textarea {
    margin-left: -1px !important;
    width: 20.7rem !important;
    height: 4rem;
  }

  .homebooknow {
    margin-left: 40px !important;
  }

  .about img {
    position: relative;
    margin-left: -19px;
    width: 100% !important;
  }

  /* .homeform {
        width: 101% !important;
        height: 410px !important;
    } */

  .best {
    position: static !important;
  }

  .bg-blue1 img {
    height: 79rem !important;
  }

  .h-001 .h-002 {
    display: flex;
    flex-direction: column;
  }

  .bg-14 {
    margin-left: 21rem;
    /* margin-top: -64px !important; */
  }

  .bg-15 .card {
    margin-top: 10px;
  }

  .bg-13 {
    margin-left: 10px;
  }

  .rw1 {
    margin-top: -25px;
  }

  .rw1 .col-lg-8 {
    margin-left: -10px;
  }

  .bg-17 {
    display: none;
  }

  .rw2 {
    margin-top: 10px;
  }

  .asdfgh .card {
    margin-top: 10px;
  }

  .login-container form {
    height: 353px !important;
    width: 320px !important;
    margin-bottom: 20px;
    box-shadow: unset;
  }

  .login-container input[type="password"] {
    margin-left: 0px !important;
  }

  .login-container input[type="text"] {
    margin-left: 0px !important;
  }

  .cs {
    overflow: hidden;
    overflow-x: scroll;
  }

  .main_employeee form {
    width: unset;
    margin-left: -30px;
  }

  .timeadd {
    /* position: absolute; */
    top: 44.7rem;
    left: 16rem;
    width: 4rem !important;
  }

  .timeinput {
    /* margin-left: -18px !important; */
    width: 386px;
  }

  .main_employeeee select {
    width: 386px;
  }

  .main_employeeee textarea {
    border: 1px solid black;
    width: 24rem;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .id_card_front {
    margin-left: -42px !important;
    float: unset !important;
  }
  .id_background {
    height: unset !important;
  }
  .totalAmount {
    width: 271px !important ;
  }
  .recomnded {
    position: absolute;
    padding: 5px;
    background-color: #ffc107;
    top: 13px;
    color: #fff;
    right: unset !important;
    transform: unset !important;
  }
  .dfghjkl_0 {
    margin-left: -50px !important;
  }

  .sdfghj_34 {
    height: 21rem !important;
  }

  .bg-26,
  .bg-27 {
    width: 17rem !important;
    margin-left: -57px !important;
  }
  .box-content .card {
    width: 17rem !important;
    height: 12rem !important;
    margin-left: -58px !important;
  }
  .loc {
    left: 146px !important;
  }
  #Stextarea {
    margin-left: 93px !important;
  }
  #Rpassword1 {
    margin-left: 61px;
  }
}
/* ganesh */
.customer-bg-img::before {
  content: "";
  background: rgba(22, 22, 22, 0.65) !important;
  position: absolute;
  /* top: 0; */
  left: 0;
  width: 100%;
  height: 400px;
  z-index: 0;
}
/* 
  .iWchow {
    box-sizing: border-box;
    transition: all 0.3s ease 0s;
    font-size: 1.6em;
    background-color: rgb(255 74 74) !important;
    color: rgb(0 0 0) !important;
    box-shadow: rgb(171 56 56) 0px 0px 2px 0px !important;
    border-radius: 50%;
    border: none;
    padding: 0px;
    width: 50px;
    height: 50px;
    min-width: 50px;
    line-height: 50px;
    align-self: center;
    cursor: pointer;
    outline: none;
}

  .bqCWu  {
    box-sizing: border-box;
    transition: all 0.3s ease 0s;
    font-size: 1.6em;
    background-color: rgb(255 74 74) !important;
    color: rgb(0 0 0) !important;
    box-shadow: rgb(171 56 56) 0px 0px 2px 0px !important;
    border-radius: 50%;
    border: none;
    padding: 0px;
    width: 50px;
    height: 50px;
    min-width: 50px;
    line-height: 50px;
    align-self: center;
    cursor: pointer;
    outline: none;
} */

.courses-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #fdc400;
  color: #000;
  border: none;
  padding: 8px 5px;
  font-size: 14px;
  line-height: 1.1;
  letter-spacing: 0.04em;
  font-weight: 500;
  text-transform: uppercase;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  text-decoration: none;
}

.courses-btn:hover {
  color: #ffffff !important;
}

.caranimatin-left-head {
  font-size: 40px;
  padding-bottom: 1px;
  line-height: 1.2;
  letter-spacing: 0.005em;
  font-weight: lighter;
  /* font-family: "Roboto", sans-serif; */
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: -120px;
  margin-top: var(--bs-dropdown-spacer);
}

.kVtMtA {
  position: absolute;
  top: calc(50% - 17.5px);
  height: 35px;
  width: 35px;
  background: #fccc0b !important;
  border-radius: 50%;
  box-shadow: 0 0 5px 0 #0009;
  z-index: 10;
  cursor: pointer;
  font-size: 10px;
  opacity: 0.6;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  left: -21px !important;
  right: initial;
}

.bhRnqO {
  position: absolute;
  top: calc(50% - 17.5px);
  height: 35px;
  width: 35px;
  background: #fccc0b !important;
  border-radius: 50%;
  box-shadow: 0 0 5px 0 #0009;
  z-index: 10;
  cursor: pointer;
  font-size: 10px;
  opacity: 0.6;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  left: initial;
  right: -22px !important;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: #ffffff !important;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: #9d041b !important;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}

.dropdown-item:hover {
  background-color: #ffffff !important;
}
/* ganesh */

.recomended {
  position: relative;
}
.recomnded {
  position: absolute;
  padding: 5px;
  background-color: #ffc107;
  top: 24px;
  color: #fff;
  right: 73px;
  transform: skew(10deg, 10deg);
}
.dnjjj_0 li {
  font-size: 10px;
  list-style: none;
}
.footer_list li {
  list-style: none;
  color: white;
}
.footer_list {
  margin-top: 20px;
}
.footer_list li a {
  text-decoration: none;
  color: white;
}
.fghit_0 {
  width: 19rem !important;
  margin-left: 10px;
}
.active {
  color: white !important;
  background-color: #9d041b;
  border-radius: 24px;
}
.active:hover {
  color: #fff !important;
}
.active1 {
  background-color: #8d2d1a;
  color: white !important;
  padding: 10px;
  margin: 10px 0px;
}
